import GoToTop from '../../Components/GoToTop'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'

export default function MarketingCompliance() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const clickMarketingCompliance = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Compliance' })
    handleShow()
  }

  const initialState = {
    to: '',
    name: '',
    email: '',
    messageBody: '',
  }
  const [eachEntry, setEachEntry] = useState(initialState)
  let { name, email, messageBody, to } = eachEntry

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  const handleMarketingComplianceSubmit = (e) => {
    e.preventDefault()
    fetch('https://hypotec-lo-campus-api.web.app/complianceMessage', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.')
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form

          handleClose()
        } else if (response.status === 'fail') {
          alert('Message failed to send.')
        }
      })
  }

  return (
    <div className="marketingCompliancePage">
      <div>
        <h2 style={{ marginLeft: '5px' }}>
          Marketing <span style={{ color: '#009ee5' }}>Compliance</span>
        </h2>
        <p style={{ marginLeft: '5px' }}>
          Have any compliance questions?
          <br />
        </p>
          <Button className="Btn" onClick={clickMarketingCompliance}>
            Contact our Compliance Team
          </Button>
      </div>
      <div className="marketingCompliancePDFs">
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingCompliance%2FMarketing%20Requirements.pdf?alt=media&token=08321ed1-cb25-4a53-93e1-b04499ffb303"
          target="_blank"
          style={{ width: '30%' }}
        >
          <img
            src="/assets/Screen Shot 2021-11-05 at 3.40.09 PM.png"
            alt="Marketing Compliance PDF"
            style={{ width: '60%' }}
          />
          <br />
          Marketing Requirements
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingCompliance%2FSocial%20Media%20Tips.pdf?alt=media&token=82642811-42cf-4f5a-a206-21cde2239027"
          target="_blank"
          style={{ width: '30%' }}
        >
          <img
            src="/assets/Screen Shot 2021-11-08 at 12.10.32 PM.png"
            alt="Social Media Best Practice PDF"
            style={{ width: '60%' }}
          />
          <br />
          Social Media Best Practice
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingCompliance%2FRESPA%20Section%208%20Training%20Slides.pdf?alt=media&token=a5e4bdcb-666c-4f28-8268-a05786cdf52f"
          target="_blank"
          style={{ width: '30%' }}
        >
          <img
            src="/assets/Screen Shot 2022-03-11 at 9.33.52 AM.png"
            alt="RESPA Section 8 pdf"
            style={{ width: '60%' }}
          />
          <br />
          RESPA Section 8
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingCompliance%2FAdvertising%20Guidelines%2011%2002%202022.docx?alt=media&token=7d91b378-f41e-4daa-a255-003e1ffba4f7"
          target="_blank"
          style={{ width: '30%' }}
        >
          <img
            src="/assets/Screen Shot 2022-03-18 at 3.45.56 PM.png"
            alt="Advertizing guidelines"
            style={{ width: '60%' }}
          />
          <br />
          Advertising Guidelines 
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} className="messageModal">
        <Modal.Header className="modal-title" closeButton closeLabel="">
          <Modal.Title>{`${to} question`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <p>Please note all questions will be answered within 72 hours.</p>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                onChange={handleOnChange}
                value={name}
              />
            </Form.Group>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                onChange={handleOnChange}
                value={email}
              />
            </Form.Group>
            <Form.Group
              style={{ padding: '10px' }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Compliance Question</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="messageBody"
                type="text"
                value={messageBody}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Button
              style={{ margin: '10px' }}
              variant="primary"
              type="submit"
              onClick={handleMarketingComplianceSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <GoToTop />
    </div>
  )
}
