import GoToTop from '../../Components/GoToTop'

export default function BankStatement() {
  return (
    <div className="bankStatementPage">
      <h2>
        Bank Statement/1099
        <span style={{ color: '#009ee5' }}> Loan</span>
      </h2>
      <div>
        <li style={{ fontWeight: 'bold' }}>
          USED FOR SELF EMPLOYED INDIVIDUALS
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          USED WHEN TAX RETURNS DO NOT QUALIFY FOR DTI REASONS
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          IN 3, 12, & 24 MONTH BANK STATEMENT PRODUCTS AVAILABLE (BETTER PRICING ON 24
          MONTHS)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          QUALIFY USING ALL BUSINESS DEPOSITS IN BANK STATEMENTS
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          DEDUCT UP TO 50% OF DEPOSITS (WE CAN LOWER THAT % IF WE GET A <br />
          ACCOUNTANT LETTER CONFIRMING THE TRUE EXPENSE RATIO OF THE BUSINESS
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>DTI UP 50% </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          MINIMUM SCORE 620 (BEST SUITED FOR 680 +)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          PURCHASE LTV - UP TO 90% 
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 89.99%
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>CASH OUT REFINANCE LTV – 80% MAX</li>
      </div>
      <GoToTop />
    </div>
  )
}
