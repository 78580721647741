import GoToTop from '../../Components/GoToTop';

export default function LinkedInBanner() {
  return (
    <div className="linkedinPosts">
      <h2>
        LinkedIn <span style={{ color: '#009ee5' }}>Posts</span>
      </h2>
      <div className="gallery">
        <div>
          <button className="container">
            <a href="/assets/Cleared to close - LI customize.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FCleared%20to%20close%20-%20LI%20customize.jpg?alt=media&token=73d64907-4018-4815-888c-26424cbeffde"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/Linkedin LO flyer 2 to customize  .jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FLinkedin%20LO%20flyer%202%20to%20customize%20%20.jpg?alt=media&token=37fbf6bd-0a7f-49dd-b283-a09907a20154"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/Linkedin LO flyer to customize.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FLinkedin%20LO%20flyer%20to%20customize.jpg?alt=media&token=19a1d0e2-cb86-4eda-a697-43fc87f9e2b8"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment post 2 (LI).jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2Frecruitment%20post%202%20(LI).jpg?alt=media&token=64ee8ca1-e59a-4347-875f-36dc7d8ad632"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment posts (LI).jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2Frecruitment%20posts%20(LI).jpg?alt=media&token=be795fc0-f0cf-41fa-9a79-b07ca30a714f"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/Same day approval.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FSame%20day%20approval.jpg?alt=media&token=7045fb89-1205-4cf0-88a3-cca64fc2206b"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/CTC TEMPLATE.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FCTC%20TEMPLATE.jpg?alt=media&token=760d4265-2835-456b-bcc4-9213ff6dd5f0"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/CTC refi.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FCTC%20refi.jpg?alt=media&token=685ba9b1-b256-4180-b8c6-bad807f6228a"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment post 1.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2Frecruitment%20post%201.jpg?alt=media&token=0d56c6bf-a848-4cf0-ad99-c6ca0f965e5c"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment post 2.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2Frecruitment%20post%202%20(LI).jpg?alt=media&token=64ee8ca1-e59a-4347-875f-36dc7d8ad632"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
      </div>
      <GoToTop />
    </div>
  );
}
