import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function NonTraditional() {
  const history = useHistory()
  const bankStatementButton = () => history.push('/bankStatement')
  const dscrLoanButton = () => history.push('/dscrLoan')
  const yr40Button = () => history.push('/yr40')
  const foreignNationalsButton = () => history.push('/foreignNationals')
  const bridgeLoansButton = () => history.push('/bridgeLoans')
  const commercialLoansButton = () => history.push('/commercialLoans')
  const noIncomesButton = () => history.push('/noIncome')

  return (
    <div className="nonTraditionalPage">
      <h2>
        NonTraditional <span style={{ color: '#009ee5' }}>Mortgages</span>
      </h2>
    <div className="nonTraditionalBtns">
      <Button
        variant="primary"
        className="Btn"
        onClick={bankStatementButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Bank Statement/1099 </h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={dscrLoanButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Debt Service Coverage Ratio Loan</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={yr40Button}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>40 YR Interest Only</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={foreignNationalsButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Foreign Nationals</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={bridgeLoansButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Bridge Loans</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={commercialLoansButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Commercial Loans </h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={noIncomesButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>No Income No Employment Loan</h3>
      </Button>
      </div>
      <GoToTop/>
    </div>
  )
}
