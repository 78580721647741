import Button from 'react-bootstrap/Button'
import GoToTop from '../../Components/GoToTop'

export default function Benefits() {
  return (
    <div className="benefitsPage">
      <h2>
        Hypotec <span style={{ color: '#009ee5' }}>Benefits</span>
      </h2>
      <div className="benefitsBtns">
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/benefits%2FBCBS%20FL%20Medical%2014101.pdf?alt=media&token=96274eb9-3b2c-4251-a03e-bef3b9e99120"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/Screen Shot 2021-12-27 at 1.17.36 PM.png"
            alt="Medical Benefits option 1 front page"
            style={{ width: '40%' }}
          />
          <br />
          Florida Blue Blue Option 14104
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/benefits%2FBCBS%20FL%20Medical%2019853.pdf?alt=media&token=9ce8e3ab-f957-4ffd-87c8-6ce31f8c624b"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/Screen Shot 2021-12-27 at 1.17.59 PM.png"
            alt="Medical Benefits option 2 front page"
            style={{ width: '40%' }}
          />
          <br />
          Florida Blue Simply Blue 19853
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/benefits%2FHypotec%2C%20Inc%20-%20Dental%20Summary.pdf?alt=media&token=27584941-7510-42e5-9cd5-748956ce0b31"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/Screen Shot 2021-12-27 at 1.18.19 PM.png"
            alt="Dental Benefits front page"
            style={{ width: '40%' }}
          />
          <br />
          Unum Dental Summary
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/benefits%2FHypotec%2C%20Inc%20-%20Vision%20Summary.pdf?alt=media&token=65c02410-7787-4b03-abb2-8b4dacdf7770"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/Screen Shot 2021-12-27 at 1.18.29 PM.png"
            alt="Vision Benefits front page"
            style={{ width: '40%' }}
          />
          <br />
          Unum Vision Summary
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/benefits%2FBenefit401kGuide.pdf?alt=media&token=48fdf2a2-477b-4487-9674-f863686411e3"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/Benefits401kGuide.png"
            alt="401k Benefits front page signs say advise, guidance, and support"
            style={{ width: '40%' }}
          />
          <br />
          401k Benefits Guide
        </Button>
      </div>
      <GoToTop />
    </div>
  )
}
