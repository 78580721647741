import GoToTop from '../../Components/GoToTop'

export default function CommercialLoans() {
  return (
    <div className="commercialLoansPage">
      <h2>
        Commercial <span style={{ color: '#009ee5' }}>Loans</span>
      </h2>
      <div>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          NEED RENT ROLL AND PROPERTY DESCRIPTION TO GET LETTER OF INTENT
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          LTV UP TO 75%
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          TERMS ARE USUALLY FOR 5-10 YEARS – PAYMENT AMORTIZED ON 20-25 YEARS
        </li>
      </div>
      <GoToTop />
    </div>
  )
}
