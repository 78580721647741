import GoToTop from '../../Components/GoToTop'

export default function FacebookPosts() {
  return (
    <div className="facebookPostsPage">
      <h2>
        Facebook <span style={{ color: '#009ee5' }}>Posts</span>
      </h2>
      <div className="gallery">
        <div>
          <button className="container">
            <a href="/assets/FB1.jpeg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FFB1.jpeg?alt=media&token=9781bd1c-9f26-4c92-87ee-e8efdff6ab69"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FB2.jpeg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FFB2.jpeg?alt=media&token=a8e6476a-1124-4d06-b335-95fd7683f5c3"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FB3.jpeg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FFB3.jpeg?alt=media&token=eb4e509a-16cd-4bfc-b4ad-944e0e8908ae"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FB4.jpeg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FFB4.jpeg?alt=media&token=9c65a979-dd24-422e-a840-c6d004903244"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/ForeignNationalLoanFBpost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FForeignNationalLoanFBpost.jpg?alt=media&token=0072d238-b1c1-43f6-83e6-acb482c0527d"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/JumboLoanFBpost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FJumboLoanFBpost.jpg?alt=media&token=33f415ef-44e5-4f60-bac6-3c85e9094168"
                alt=""
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/D2IFBpost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FD2IFBpost.jpg?alt=media&token=ad525baa-f2c9-4b50-8fcf-e61140b54a88"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/D2IratioFBpost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FD2IratioFBpost.jpg?alt=media&token=49a5f29c-46e2-4651-a8fe-a602b3b5d68d"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/ICANHELPFBPOST.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FICANHELPFBPOST.jpg?alt=media&token=b0dee7aa-bde6-4b12-b2e9-d691e4224a07"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/QUOTETEMPLATEFB.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FQUOTETEMPLATEFB.jpg?alt=media&token=79a90dec-664a-4499-82d5-88b07556d3be"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/ClosingDayFB.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FClosingDayFB.jpg?alt=media&token=1cae5071-bdea-4c72-af10-d7bd39344342"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/credit score _ FB pst.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2Fcredit%20score%20_%20FB%20pst.jpg?alt=media&token=9d4831ec-8d25-4935-8ddf-7e3c0a38e3c1"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a
              href="/assets/customize with LO contact info - FB poost.jpg"
              download
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2Fcustomize%20with%20LO%20contact%20info%20-%20FB%20poost.jpg?alt=media&token=981fbc27-3be3-4ec3-a41d-72e4604325a0"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a
              href="/assets/customize with contact details - FBLI post .jpg"
              download
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2Fcustomize%20with%20contact%20details%20-%20FBLI%20post%20.jpg?alt=media&token=ca863b6f-5da4-43ac-b1d5-75872bd45f68"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a
              href="/assets/recruitment - unlimited earning - FBLI post .jpg"
              download
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2Frecruitment%20-%20unlimited%20earning%20-%20FBLI%20post%20.jpg?alt=media&token=a510d12c-a070-4621-8a0d-292167c3aa5b"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FB1.jpeg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2Frefinance%20question%20(facebook%20LI%20post).jpg?alt=media&token=15c2f27e-8b92-4440-8110-a61e84c06afe"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a
              href="/assets/time to buy customize template (Facebook Post).jpg"
              download
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2Ftime%20to%20buy%20customize%20template%20(Facebook%20Post).jpg?alt=media&token=cbb21ce5-a98d-4176-8412-47532255c3eb"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/Same day approval.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FSame%20day%20approval.jpg?alt=media&token=7fb49bb8-d886-4984-a8d3-d3e347f12337"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/CTC refi.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FCTC%20refi.jpg?alt=media&token=32671294-8e8c-45b7-97a7-c577d22e758a"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/CTC TEMPLATE.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookPosts%2FCTC%20TEMPLATE.jpg?alt=media&token=1fd99139-19ca-4a05-a97d-9a0ff3926870"
                alt=""
                className="image"
                style={{ width: '200px', height: '165px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
