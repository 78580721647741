import GoToTop from '../../Components/GoToTop'

export default function InstagramPosts() {
  return (
    <div className="instagramPostsPage">
      <h2>
        Instagram <span style={{ color: '#009ee5' }}>Posts</span>
      </h2>
      <div className="gallery">
        <div>
          <button className="container">
            <a href="/assets/IGpost1.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost1.jpg?alt=media&token=d5a2b6d3-59fa-40b0-bab2-702239b549bd"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGpost2.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost2.jpg?alt=media&token=4493072f-8fb3-4e46-97f3-6bf6a44e0a1d"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGpost3.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost3.jpg?alt=media&token=8bc1e0ce-c9f8-4481-a83b-3d349a7da3c9"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGpost4.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost4.jpg?alt=media&token=6066e5c4-f04b-407c-83da-26ffc7e91263"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGpost5.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost5.jpg?alt=media&token=58d832c0-9aca-4cdb-a980-bb66086f3869"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGpost6.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost6.jpg?alt=media&token=b76132e9-d784-413c-857b-6a7e2496e2bb"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGpost7.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FIGpost7.jpg?alt=media&token=88ad66f2-1eae-4b92-913e-81f9941b54fd"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/LGBrandedTempBlank.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FLGBrandedTempBlank.jpg?alt=media&token=f75ba72f-d539-4f8f-baa5-b2e914522bc8"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/LGQuoteTempBlank.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FLGQuoteTempBlank.jpg?alt=media&token=fb97038f-bad1-494e-87c5-e081ae07a12a"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/ReadyTopMoveIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FReadyTopMoveIG.jpg?alt=media&token=cc2034ea-0a4f-4b98-81a5-0bd484a1d444"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/D2IFactIGPost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FD2IFactIGPost.jpg?alt=media&token=15771d86-4525-4846-b35d-3da59a2ad493"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/JoinHypotecTeamIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FJoinHypotecTeamIG.jpg?alt=media&token=4c5e0fa6-59c3-415c-86d5-0ee60bc36f83"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/JumboLoanIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FJumboLoanIG.jpg?alt=media&token=63c6c503-98b0-434f-82e1-a728d47c4736"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/D2IIGPost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FD2IIGPost.jpg?alt=media&token=2ec6fc4b-6e48-4e17-95e9-1cf532898653"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/BuyNowIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FBuyNowIG.jpg?alt=media&token=a34adaa9-27a9-4d37-bc29-9438702d9542"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/ClosingDayIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FClosingDayIG.jpg?alt=media&token=be0728cb-c3fa-4882-a95c-1349492012eb"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/HomeSweetHomeIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FHomeSweetHomeIG.jpg?alt=media&token=a2a91c54-4818-4763-875b-ae0b5ee95c8a"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/HowMuchSavingsIG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FHowMuchSavingsIG.jpg?alt=media&token=ffc0c6a1-071c-468d-bad3-8b1eb995d74f"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FAQ 1 - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FFAQ%201%20-%20IG%20post.jpg?alt=media&token=5961698e-3482-45ff-81cb-e9880501f7e5"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FAQ 2 - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FFAQ%202%20-%20IG%20post.jpg?alt=media&token=72b80f95-f038-4d8a-a22b-04c34fa8e7ab"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FAQ 3 - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FFAQ%203%20-%20IG%20post.jpg?alt=media&token=e28280d1-61e0-4a5d-b487-35dacfba04a3"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FAQ 4 - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FFAQ%204%20-%20IG%20post.jpg?alt=media&token=f4a6e52b-d170-467d-b244-8270ef2ba6ef"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/FAQ 5 - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FFAQ%205%20-%20IG%20post.jpg?alt=media&token=60756e02-91f0-406f-99a8-369cfaec1c00"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/branded quote template 2.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Fbranded%20quote%20template%202.jpg?alt=media&token=bc5f849d-6f57-44ab-a2c5-49f7b1258f76"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/faq 6.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Ffaq%206.jpg?alt=media&token=e503a295-14d1-47d8-882a-5ab0580b0090"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/faq 7.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Ffaq%207.jpg?alt=media&token=786874f9-dcc9-4c80-9376-7159b38a9516"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/faq 8.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Ffaq%208.jpg?alt=media&token=d1be4999-3fb0-435d-a398-45e656ed7551"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/start with me.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Fstart%20with%20me.jpg?alt=media&token=5266caae-aaa8-49e7-abb7-a7d4c322ac75"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/credit score - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Fcredit%20score%20-%20IG%20post.jpg?alt=media&token=eddeca12-32df-4331-b337-d590e25eb1c9"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/experience - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Fexperience%20-%20IG%20post.jpg?alt=media&token=554d0d0e-0cbc-470d-8d07-c51025a2b817"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/Q&A - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2FQ%26A%20-%20IG%20post.jpg?alt=media&token=c33ebdac-b3a4-457e-b746-d60cec4f300e"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment - unlimited earning -  (Instagram Post).jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Frecruitment%20-%20unlimited%20earning%20-%20%20(Instagram%20Post).jpg?alt=media&token=454a4800-38d0-48c7-bf4f-ad90c18e8789"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/customize with contact details - IG post.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Fcustomize%20with%20contact%20details%20-%20IG%20post.jpg?alt=media&token=548089af-a3b2-4c71-b2ef-5a3bae280c3c"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/time to buy customize template igpost.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Ftime%20to%20buy%20customize%20template%20igpost.jpg?alt=media&token=71e08f6f-f366-4af9-9987-3b96ad5ad203"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment post 1 IG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Frecruitment%20post%201%20IG.jpg?alt=media&token=bf025445-d383-4ad0-ad21-259b5a66e698"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/recruitment post 2 IG.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramPosts%2Frecruitment%20post%202%20IG.jpg?alt=media&token=6efc3a57-ef79-4cd0-b9a3-85fba7b05c7f"
                alt=" "
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
