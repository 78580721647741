import GoToTop from '../../Components/GoToTop'

export default function MarketingVideos() {
  return (
    <div className="marketingVideosPage">
      <h2>
        Marketing <span style={{ color: '#009ee5' }}>Videos</span>
      </h2>
      <div className="videoContainer">
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%204.23.45%20PM.png?alt=media&token=03f39832-2bf1-4491-a087-caeaaee39711"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FMandy-FB101.mp4?alt=media&token=82ec29de-308d-426e-8773-e7305a2fc215"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%204.24.04%20PM.png?alt=media&token=b4c26217-a03c-44f7-ba34-d4942fe7f70f"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FMandy-Instagram101.mp4?alt=media&token=4d73a1c6-3a67-48b1-a726-cfc6341956cd"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%204.24.50%20PM.png?alt=media&token=5ed42092-d9b1-490e-977b-4bca9033fc81"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FMandy-Linkedin%20101.mp4?alt=media&token=dd71eb43-f122-474e-8f7a-eac6c2a882ae"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202022-01-10%20at%2012.02.16%20PM.png?alt=media&token=2e17aaf4-bd3a-46a4-a669-2eeb8cef2db0"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FHow%20to%20sign%20up%20for%20Canva.mp4?alt=media&token=5426ac1c-6380-48e0-8954-e7c3a14dbea2"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202022-01-10%20at%2012.06.32%20PM.png?alt=media&token=25defad8-8c7b-4d02-9280-139712d48edd"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FHow%20to%20add%20your%20photo%20and%20contact%20info%20to%20a%20template%20using%20Canva.mp4?alt=media&token=1ebd2a4f-8afb-4134-a73f-7ddcc45394b4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202022-01-10%20at%2012.06.44%20PM.png?alt=media&token=38eb16a1-476f-4d7c-92d2-3d3c3d8b2caa"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FHow%20to%20edit%20a%20template%20from%20MRC%20in%20Canva.mp4?alt=media&token=44086a65-9edc-48f5-8164-2924c6dc5943"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202022-01-10%20at%2012.06.52%20PM.png?alt=media&token=9f5b913b-3e90-4281-8b4e-c7a346c6f347"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FHow%20to%20create%20an%20image%20to%20promote%20a%20google%20review%20using%20Canva.mp4?alt=media&token=3a779d97-c9d7-4758-8447-5bd9f46eb83d"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
