import GoToTop from '../../Components/GoToTop'
import Container from 'react-bootstrap/Container'

export default function CommonQualifying() {
  return (
    <div className="commonQualifyingPage">
      <h2>
        Solutions to Common Qualifying{' '}
        <span style={{ color: '#009ee5' }}>Issues</span>
      </h2>
      <Container className='commonContainer'>
        <h5 style={{ color: 'red' }}>Qualifying issue:</h5>
        <h6 style={{ color: 'red' }}>DTI too High</h6>
        <h5>Possible Solutions:</h5>
        <ol>
          <li>Find a cheaper HOI</li>
          <li>Add a co borrower – Non-Occupant Borrower</li>
          <li>
            See if client has assets that we can use as part of the asset
            depletion calculation to increase income.
          </li>
          <li>More down payment = Lower Loan Amount / Payment</li>
          <li>Payoff liabilities at closing</li>
          <li>
            Look at excluding car payments that maybe paid by a business or
            another person not on the loan.
          </li>
        </ol>
        <p>
          <mark>
            If all of the above are not working and client has 20% Down Payment,
            start looking at NonQM Loans. For example, the NO INCOME / NO
            EMPLOYMENT Loan.
          </mark>
        </p>
      </Container>
      <Container className='commonContainer'>
        <h5 style={{color:'red'}}>
        Qualifying issue:
        </h5>
        <h6 style={{ color: 'red' }}>Credit Score to Low</h6>
        <h5>Possible Solutions:</h5>
        <ol>
          <li>Use the Credit Simulator to see what Client can pay down to raise Credit Scores.</li>
          <li>Shop for different loan products that may allow a lower credit score, for example: FHA Loans.</li>
        </ol>
      </Container>
      <Container className='commonContainer'>
        <h5 style={{color:'red'}}>
        Qualifying issue:
        </h5>
        <h6 style={{ color: 'red' }}>Not enough Down Payment funds available</h6>
        <h5>Possible Solutions:</h5>
        <ol>
          <li>Ask client if they can get a Gift from a Family member</li>
          <li>See if client has any Stocks, Bonds, 401k, IRA that can be withdrawn to use as assets to close.</li>
          <li>Look into other programs that may allow lower down payments.</li>
          <li>Possibly get a 1st mortgage at 75% LTV and a 2nd Mortgage to have a combined CLTV of 90% +</li>
          <li>Try and negotiate a Seller Credit to cover some closing costs.</li>
          <li>Offer a higher rate which will cost less money and offset the closing costs.</li>
        </ol>
      </Container>
      <Container className='commonContainer'>
        <h5 style={{color:'red'}}>
        Qualifying issue:
        </h5>
        <h6 style={{ color: 'red' }}>Employment History / Change from W2 to S-E</h6>
        <h5>Possible Solutions:</h5>
        <ol>
          <li>If client was W2’d and now is S-E, the rule of thumb is that we need a minimum of 13 months in the S-E field with at least 1 full year of tax returns</li>
          <li>If the above is not the case, you may need to offer NonQM programs such as 12-month bank statement loan, No Income / No Employment loan.</li>
          <li>Add a co-borrower / non-Occupant borrower to help on the income.</li>
        </ol>
      </Container>
      <GoToTop />
    </div>
  )
}
