import GoToTop from '../../Components/GoToTop'

export default function Conventional() {
  return (
    <div className="conventionalPage">
      <div>
        <h2>
          Conventional <span style={{ color: '#009ee5' }}>Loans</span>
        </h2>
        <h5>Fannie Mae</h5>
        <h5>Freddie Mac</h5>
      </div>
      <div>
        <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 620</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>PURCHASE LTV - UP TO 97%</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 95%
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>CASH OUT REFINANCE LTV – 80% MAX</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          DTI UP TO 41% / 50% (ANYTHING ABOVE 45% MAY NEED 6 MONTHS RESERVES)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          ELIGIBLE FOR APPRAISAL WAIVERS BASED ON DU / LP FINDINGS (MORE COMMON
          ON RATE AND TERM REFIS)
        </li>
      </div>
      <GoToTop />
    </div>
  )
}
