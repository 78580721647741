import GoToTop from '../../Components/GoToTop'

export default function MortgageVideos() {
  return (
    <div className="mortgageVideosPage">
      <h2>
        Mortgage <span style={{ color: '#009ee5' }}>Videos</span>
      </h2>
      <div className="videoContainer">
        <div className='videos'>
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%204.12.48%20PM.png?alt=media&token=0db370af-8dfe-4bc6-973f-c2751b90d77c"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddyMortgageProductFundamentals.mp4?alt=media&token=ea78aa85-d0ea-4814-b6fc-f1d663d4fff1"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
