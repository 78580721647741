import GoToTop from '../../Components/GoToTop'

export default function Jumbo() {
  return (
    <div className="jumboPage">
      <h2>
        Jumbo <span style={{ color: '#009ee5' }}>Loans</span>
      </h2>
      <div>
        <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 680</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>PURCHASE LTV - UP TO 96.5%</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 96.5%
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>CASH OUT REFINANCE LTV – 80% MAX</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>DTI UP TO 41% / 45% MAX</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>FOLLOW FANNIE MAE DU FINDINGS</li>
      </div>
      <GoToTop />
    </div>
  )
}
