import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import GoToTop from '../../Components/GoToTop'

export default function PayrollSubmit() {
  return (
    <div className="payrollSubmitPage">
      <h2>
        How to Submit <span style={{ color: '#009ee5' }}>Payroll</span>
      </h2>
      <div className="payrollSubmitContainer">
        <Container fluid>
          <Row>
            <Col>
              <ul>
                <li>
                  Once a pay period finishes, begin the bi-weekly/monthly
                  payroll process
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Make sure you are on the correct account; 2163 (biweekly) 2165
                  (monthly)
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Do you prefer that I have a MAC or Windows operating system?
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>Get the time cards for the period from paychex</li>
                <ul>
                  <li type="circle">Time & Attendance Dashboard</li>
                  <li type="circle">Reports</li>
                  <li type="circle">
                    Single page timecard reports (on the left side)
                  </li>
                  <li type="circle">Enter Pay Period Dates</li>
                </ul>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Create a new excel spreadsheet for the pay period (Located in
                  Elsies Doc’s)
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Calculate the total # of hours & OT and fill out the entire
                  sheet for each employee
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Send Freddy/Benny/Danny the excel sheet as well as the
                  employee’s timecards
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Once approved by Freddy/Danny/Benny, begin to enter the
                  information into Paychex
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>Hit the ‘Begin’ payroll button on Paychex</li>
                <ul>
                  <li type="circle">
                    Select that you will manually be entering in the checks
                  </li>
                  <li type="circle">
                    Go thru terms on Excel Sheet and bring them back
                  </li>
                  <li type="circle">
                    More options – Search for term – Add employee
                  </li>
                  <li type="circle">
                    Fill out the HOURS and OT fields for each employee
                  </li>
                  <ul>
                    <li>
                      Only fill out the total $ amount field for Freddy &
                      Nathalie, as they are salaried
                    </li>
                  </ul>
                  <li type="circle">
                    Double check your work by using the Excel sheet
                  </li>
                </ul>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>Once finished, hit review and submit</li>
                <ul>
                  <li type="circle">Download all available PDF’s</li>
                  <li type="circle">
                    Save the Payroll Journal and Cash Requirements
                  </li>
                </ul>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Double check the Payroll Journal’s deductions match with our
                  health insurance deductions and that everything matches
                  (hours, OT, etc.) OR you can ‘check preview’ each employee and
                  go one by one
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  After reviewing the payroll journal & verified that everything
                  matches, send an email to Elsie and CC Freddy with the
                  following information:
                </li>
                <ul>
                  <li type="circle">Payroll Journal Attachment</li>
                  <li type="circle">Cash Requirements Attachment</li>
                </ul>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>Submit the payroll by clicking ‘Submit now!’ on Paychex</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  Make a calendar reminder for when the next Bi-Weekly/Monthly
                  payroll needs to be submitted
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <GoToTop />
    </div>
  )
}
