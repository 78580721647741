import GoToTop from "../../Components/GoToTop"

export default function YR40() {
  return (
    <div className="yr40Page">
      <h2>
        40 YR Interest <span style={{ color: '#009ee5' }}>Only</span>
      </h2>
      <div>
        <br />
        <li style={{ fontWeight: 'bold' }}>1ST 10 YEARS ARE I/O AND THE NEXT 30 YEARS ARE FIXED</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>GREAT FOR SOMEONE LOOKING FOR LOWER PAYMENTS</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>MAX DTI 45% </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 660</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>PURCHASE LTV  - UP TO 90% </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>RATE AND TERM REFINANCE LTV – UP TO 90%</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>CASH OUT REFINANCE LTV – 80% MAX</li>
      </div>
      <GoToTop/>
    </div>
  )
}
