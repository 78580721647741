import Button from 'react-bootstrap/Button'
import GoToTop from '../../Components/GoToTop'

export default function MarketingTemplates() {
  return (
    <div className="articlesPage">
      <div>
        <h2>
          Marketing <span style={{ color: '#009ee5' }}>Templates</span>
        </h2>
      </div>
      <div className="articles">
        <Button
          className="btn"
          variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FAll%20Programs-0.png?alt=media&token=926a5e05-964d-4752-b59d-2aa7dd26d8c5"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/All-Programs-0.png"
            alt="All Programs PNG"
            style={{ width: '60%' }}
          />
          <br />
          All Programs
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FApply%20For%20A%20Mortgage-0.png?alt=media&token=11203ede-9c50-452f-9f63-9d484eacd0d8"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Apply-For-A-Mortgage-0.png"
            alt="Apply For A Mortgage PNG"
            style={{ width: '60%' }}
          />
          <br />
          Apply For A Mortgage
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FBank%20Statement-0.png?alt=media&token=ce9e49bd-9824-4fcf-a16b-d2524d1f98d0"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Bank-Statement-0.png"
            alt="Bank Statement PNG"
            style={{ width: '60%' }}
          />
          <br />
          Bank Statement
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FFacebook%20Banner-0.png?alt=media&token=530ebe13-4d07-4f7b-800f-1197f72b2518"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Facebook-Banner-0.png"
            alt="Facebook Banner PNG"
            style={{ width: '60%' }}
          />
          <br />
          Facebook Banner
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FGeneral%20Email-0.png?alt=media&token=235293a2-1f89-4b49-870b-0eabcfdcc4f9"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/General-Email-0.png"
            alt="General Email PNG"
            style={{ width: '60%' }}
          />
          <br />General Email
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FJoin%20Our%20team!-0.png?alt=media&token=1ea1cd35-79a8-4ee0-af6e-447d945d1755"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Join-Our-team-0.png"
            alt="Join Our Team PNG"
            style={{ width: '60%' }}
          />
          <br />
          Join Our Team
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FMortgage-lender-questions.jpg?alt=media&token=b8b7e095-932a-4478-862e-b17c4d2c152d"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Mortgage-lender-questions.jpeg"
            alt="Mortgage Lender Questions JPG"
            style={{ width: '60%' }}
          />
          <br />
          Mortgage Lender Questions
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FOur%20Mission-0-2.png?alt=media&token=773a1205-06f2-4b8c-85bd-73347f994c88"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Our-Mission-0-2.png"
            alt="Our Mission PNG"
            style={{ width: '60%' }}
          />
          <br />
          Our Mission
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FPre%20Approval%20Checklist-0.png?alt=media&token=1ec4e5d8-1add-41eb-bee9-1e8bd83d2ce8"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Pre-Approval-Checklist-0.png"
            alt="Pre Approval Checklist PNG"
            style={{ width: '60%' }}
          />
          <br />
          Pre Approval Checklist
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FSimple%20and%20Secure-0.png?alt=media&token=b8e08c31-511f-473c-b7f5-896bf8207911"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Simple-and-Secure-0.png"
            alt="Simple and Secure PNG"
            style={{ width: '60%' }}
          />
          <br />
          Simple and Secure
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FSolutions%20For%20Every%20Situation-0.png?alt=media&token=56eb20ba-21ec-474e-955e-b7246ab43a78"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Solutions-For-Every-Situation-0.png"
            alt="Solutions For Every Situation-0 PNG"
            style={{ width: '60%' }}
          />
          <br />
          Solutions For Every Situation
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FTagline-0.png?alt=media&token=0af64f00-9489-44e6-a78e-e360f7330526"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/marketingTemplates/Tagline-0.png"
            alt="Tagline PNG"
            style={{ width: '60%' }}
          />
          <br />
          Tagline
        </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-4%20Things%20to%20Consider%20-with-bleed.pdf?alt=media&token=1bcf8daa-fa6e-4ddd-b8ac-1251a31d117b"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-4-Things-to-Consider-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  4 Things to Consider -with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-All%20Programs%20Full.pdf?alt=media&token=fe2d040d-a03b-430d-a411-7baa96da12c7"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-All-Programs-Full.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  All Programs Full
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Bank%20Statement.pdf?alt=media&token=f09fb6c4-e202-4853-a98e-5bd780eb0f86"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Bank-Statement.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Bank Statement
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Closing%20Certificate-with-bleed.pdf?alt=media&token=d155b19e-84ab-4348-8e1c-4a359eb5bd79"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Closing-Certificate-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Closing Certificate-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Company%20Letterhead-with-bleed.pdf?alt=media&token=542278d2-4831-4fa5-82e7-a71abcb70400"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Company-Letterhead-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Company Letterhead-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Completion%20Certificate-with-bleed.pdf?alt=media&token=2c58ec17-bdf8-4ac4-88fc-bec8e88fbf4a"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Completion-Certificate-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Completion Certificate-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Dos%20and%20Donts-with-bleed.pdf?alt=media&token=4d36ac0e-a0ad-4312-9bfc-a072836e2986"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Dos-and-Donts-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Dos and Donts-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Event%20Flyer-with-bleed.pdf?alt=media&token=617ad832-81e0-45c4-8a0b-ae119cd3d3aa"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Event-Flyer-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Event Flyer-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Event%20LO%20Presentation-with-bleed.pdf?alt=media&token=2fd9ca67-b26e-4872-88a9-a4e9c9270a50"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Event-LO-Presentation-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Event LO Presentation-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-FHA%20Loans.pdf?alt=media&token=edbc2403-d93e-47dd-9992-a879d6aa4928"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-FHA-Loans.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  FHA Loans
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-First%20Time%20Homebuyers-with-bleed.pdf?alt=media&token=a448d2cd-fa67-4f32-9d1c-4945398363f5"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-First-Time-Homebuyers-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  First Time Homebuyers-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Foreign%20National.pdf?alt=media&token=51691358-28a6-4653-b6c5-c5e4fa2625e8"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Foreign-National.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Foreign National
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Getting%20Organized%20at%20Tax%20Time-with-bleed.pdf?alt=media&token=636fd556-5995-4894-953e-ba29ca183d14"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Getting-Organized-at-Tax-Time-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Getting Organized at Tax Time-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-HELOC.pdf?alt=media&token=5902a4c9-dcd7-4eac-85f2-993e7411fb85"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-HELOC.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  HELOC
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Investor%20cash%20flow.pdf?alt=media&token=546b8575-b2e9-49f4-ada8-e79cf292d40b"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Investor-cash-flow.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Investor cash flow
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Jumbo%20NON-QM.pdf?alt=media&token=d056729a-8bd4-4707-b154-52809c6c4fdc"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Jumbo-NON-QM.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Jumbo NON-QM
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Loan%20Options.pdf?alt=media&token=21834f00-91f1-4097-b3c7-19a91ed34885"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Loan-Options.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Loan Options
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Loan%20Options-2.pdf?alt=media&token=a6a78a39-091f-47c8-9048-aa567020b179"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Loan-Options-2.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Loan Options-2
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Loan%20Options-3.pdf?alt=media&token=e98b4afd-8453-42ec-96f4-e02603a8e625"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Loan-Options-3.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Loan Options-3
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-PRE-SCREEN%20PROCESS.pdf?alt=media&token=f9e977ad-c1af-497e-8180-74d9d3238365"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-PRE-SCREEN-PROCESS.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  PRE-SCREEN PROCESS
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Reasons%20to%20get%20Pre-Qualified.pdf?alt=media&token=3c34c0ea-4e1a-475f-b567-e2deadd7e690"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Reasons-to-get-Pre-Qualified.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Reasons to get Pre-Qualified
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Should%20You%20Refinance.pdf?alt=media&token=b3472a6f-6c4b-449f-9b21-1b56880beb6e"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Should-You-Refinance.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Should You Refinance
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Sign%20In%20Sheets%20(Horizontal)-with-bleed.pdf?alt=media&token=1b108619-5ae2-401f-9173-989744c70701"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Sign-In-Sheets-(Horizontal)-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Sign In Sheets (Horizontal)-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Thank%20You%20Card-with-bleed.pdf?alt=media&token=ae7e88b1-00a1-4a85-a25e-38043a5cbfd2"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Thank-You-Card-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Thank You Card-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-Thinking%20About%20Buying%20a%20Home-with-bleed.pdf?alt=media&token=cdebb79d-8aba-4c4c-8a3d-510c427b216d"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-Thinking-About-Buying-a-Home-with-bleed.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Thinking About Buying a Home-with-bleed
              </Button>
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2Fpdf-VA%20Home%20Loans.pdf?alt=media&token=e7573bca-d12a-4c59-badc-4b250c132bff"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/pdf-VA-Home-Loans.png"
                      alt="PDF File Logo PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  VA Home Loans
              </Button>
              {/*Financial Advisor (20221027)*/}
                  <Button
                      className="btn"
                      variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FFinancial%20Advisor%20A.pdf?alt=media&token=fe6a0219-e54e-43b7-9ea8-ca33591039aa"
                      target="_blank"
                      style={{ width: '25%' }}
                  >
                      <img
                          src="/assets/marketingTemplates/Financial-Advisor-20221027/Financial-Advisor-0-th.png"
                          alt="Financial Advisor 0 PNG"
                          style={{ width: '60%' }}
                      />
                      <br />
                      Financial Advisor 0
                  </Button>
          {/*Financial Advisor B (20221027)*/}
                  <Button
                      className="btn"
                      variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FFinancial%20Advisor%20B.pdf?alt=media&token=c5579cde-7744-4638-884e-3abc338e85a3"
                      target="_blank"
                      style={{ width: '25%' }}
                  >
                      <img
                          src="/assets/marketingTemplates/Financial-Advisor-B-20221027/Financial-Advisor-B-0-th.png"
                          alt="Financial Advisor B 0 PNG"
                          style={{ width: '60%' }}
                      />
                      <br />
                      Financial Advisor B 0
                  </Button>
          {/*Qualify for a Home - Multipage (20221027)*/}
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FQualify%20for%20a%20Home%20-%20Multipage.pdf?alt=media&token=e7640677-8850-4f19-95a5-11d47a267e15"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/Qualify-for-a-Home-Multipage-20221027/Qualify-for-a-Home-Multipage-0-th.png"
                      alt="Qualify for a Home - Multipage (20221027) 0 PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Qualify for a Home - Multipage (20221027) 0
              </Button>
          {/*Realtor Presentation (20221027)*/}
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FRealtor%20Presentation%20A.pdf?alt=media&token=61f3a3b0-c0fd-47ec-aa33-c3490f6c7cb0"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/Realtor-Presentation-20221027/Realtor-Presentation-0-th.png"
                      alt="Realtor Presentation (20221027) 0 PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Realtor Presentation (20221027) 0
              </Button>
          {/*Realtor Presentation B (20221027)*/}
              <Button
                  className="btn"
                  variant="link"
                  href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/marketingTemplates%2FRealtor%20Presentation%20B.pdf?alt=media&token=3871ce45-059c-4099-92e5-d30f636cdcd2"
                  target="_blank"
                  style={{ width: '25%' }}
              >
                  <img
                      src="/assets/marketingTemplates/Realtor-Presentation-B-20221027/Realtor-Presentation-B-0-th.png"
                      alt="Realtor Presentation (20221027) 0 PNG"
                      style={{ width: '60%' }}
                  />
                  <br />
                  Realtor Presentation (20221027) 0
              </Button>
      </div>
      <GoToTop />
    </div>
  )
}
