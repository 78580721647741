import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function InstagramGallery() {
  const history = useHistory()
  const igPostsClick = () => history.push('/igPosts')
  const highlightClick = () => history.push('/highlightCover')

  return (
    <div className="instagramGalleryPage">
      <div>
        <h2>
          Instagram <span style={{ color: '#009ee5' }}>Gallery</span>
        </h2>
      </div>

      <Card style={{ padding: '10px' }}>
        <br />
        <h5>Instagram Posts</h5>
        <Card.Body>
          <Button className="Btn" onClick={igPostsClick}>
            <img
              src="/assets/IGpost1.jpg"
              alt="blue brick wall with 'HITTING A BRICK WALL ON WHERE TO BEGIN IN THE HOME BUYING PROCESS?' and Hypotec logo over it"
              style={{ margin: '10px', width: '200px' }}
            />
            <br />
            See More
          </Button>
        </Card.Body>
      </Card>
      <Card style={{ padding: '10px', width: 'min-content' }}>
        <h5>
          Instagram Highlight <br /> Cover Images
        </h5>
        <Card.Body>
          <Button className="Btn" onClick={highlightClick}>
            <img
              src="/assets/IG Highlight Cover image familyedit.jpg"
              alt="gray background with blue circle- inside is a home with family of 4 and a heart inside"
              style={{ margin: '10px', width: '200px' }}
            />
            See More
          </Button>
        </Card.Body>
      </Card>
      <GoToTop />
    </div>
  )
}
