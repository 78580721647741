import GoToTop from '../../Components/GoToTop'

export default function Vendors() {
  return (
    <div className="vendorsPage">
      <h2>
        Preferred
        <span style={{ color: '#009ee5' }}> Vendors</span>
      </h2>
      <div className="vendors">
        <ul>
        <li type='circle'>
        <h6>Business Cards</h6>
        <h6>Copymasters</h6>
        <h5>Aviva Gammerman </h5>
        <h6>copymastersnmb@gmail.com </h6>
        <h6>305-940-8973</h6>
        </li>
        </ul>
      </div>
      <GoToTop />
    </div>
  )
}
