import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function MortgageProducts() {
  let history = useHistory()
  const traditionalClick = () => history.push('/traditional')
  const nonTraditionalClick = () => history.push('/nontraditional')
  const mortgageVideosClick = () => history.push('/mortgageVideos')
  const topInvestorsClick = () => history.push('/topInvestors')
  const commonClick = () => history.push('/common')
  const investorsClick = () => history.push('/investorsContact')

  return (
    <div className="mortgageProductsPage">
      <div>
        <h2>
          Mortgage <span style={{ color: '#009ee5' }}>Products</span>
        </h2>
        <h6>Didn't find what you are looking for?</h6>
        <Button
          className="Btn"
          variant="primary"
          href="https://us02web.zoom.us/j/9710308338?pwd=TTdHTVJ5Z2xDNTdxNUhjQWN5MnNLdz09"
          target="_blank"
        >
          Click Here for Support
        </Button>
        <br />
        <Button
          className="Btn"
          variant="primary"
          href="https://chat.hypotec.com"
          target="_blank"
          style={{width: '187px'}}
        >
          Hypotec Chat
        </Button>
      </div>
      <span className="mortgageProductsBtns">
        <Button
          variant="primary"
          className="Btn"
          onClick={traditionalClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Traditional</h3>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={nonTraditionalClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Non-Traditional</h3>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={topInvestorsClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Top Investors per Product</h3>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={commonClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Solutions to Common Qualifying Issues</h3>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={mortgageVideosClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Videos</h3>
        </Button>
        {/* <Button
          variant="primary"
          className="Btn"
          onClick={investorsClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Investors Contact Sheet</h3>
        </Button> */}
      </span>
      <GoToTop />
    </div>
  )
}
