import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function HypotecStore() {
  const history = useHistory()
  const giftsClick = () => history.push('/gifts')
  return (
    <div className="hypotecStorePage">
      <h2>
        Hypotec <span style={{ color: '#009ee5' }}>Store</span>
      </h2>
      <div className="storeBtns">
        <Button
          variant="primary"
          className="Btn"
          href="https://teamlocker.squadlocker.com/#/lockers/hypotec"
          target="_blank"
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
            marginLeft: '50px',
            paddingTop: '80px',
          }}
        >
          <h3>
            Employee <br /> Apparel
          </h3>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={giftsClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
            marginLeft: '50px',
          }}
        >
          <h3>Client Gifts</h3>
        </Button>
      </div>
      <GoToTop />
    </div>
  )
}
