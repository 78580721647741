import GoToTop from '../../Components/GoToTop'

export default function CoverPhotos() {
  return (
    <div className="coverPhotosPage">
      <h2>
        Facebook Cover <span style={{ color: '#009ee5' }}>Photos</span>
      </h2>
      <div className="gallery">
        <div>
          <button className="medContainer">
            <a href="/assets/fbCover1.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookCoverPhotos%2FfbCover1.jpg?alt=media&token=e95a407d-f1d4-4f84-b9d1-a1f2d8b60d9a"
                alt="white background with Hypotec logo and blue dots scattered across it"
                className="image"
                style={{ width: '300px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="medContainer">
            <a href="/assets/fbCover2.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookCoverPhotos%2FfbCover2.jpg?alt=media&token=7cef9a7a-78ab-4c89-8afc-c622a1673afa"
                alt="2 hands holding a small blue house with hypotec logo and 'If you're ready to take you career to the next level, send your resume over today and we'll be in contact with qualified applications shortly' to the right side"
                className="image"
                style={{ width: '300px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="medContainer">
            <a href="/assets/fbCover3.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookCoverPhotos%2FfbCover3.jpg?alt=media&token=04c8a5e1-31ac-4271-b027-c0e8143ac744"
                alt="blue and gray geometric background with Hypotec logo"
                className="image"
                style={{ width: '300px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="medContainer">
            <a href="/assets/fbCover4.png" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/facebookCoverPhotos%2FfbCover4.png?alt=media&token=5fb172f2-009c-433d-a7b7-1d155d683564"
                alt="all blue"
                className="image"
                style={{ width: '300px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
