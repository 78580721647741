import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function Traditional() {
  const history = useHistory()
  const conventionalButton = () => history.push('/conventional')
  const govProductsButton = () => history.push('/govProducts')
  const jumboButton = () => history.push('/jumbo')

  return (
    <div className="traditionalPage">
      <h2>
        Traditional <span style={{ color: '#009ee5' }}>Mortgages</span>
      </h2>
      <Button
        variant="primary"
        className="Btn"
        onClick={conventionalButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Conventional</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={govProductsButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Government Products</h3>
      </Button>

      <Button
        variant="primary"
        className="Btn"
        onClick={jumboButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Jumbo</h3>
      </Button>
      <GoToTop />
    </div>
  )
}
