//import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCNJdkuOHqBf6dOMzpEAfWdhpAZIyEwrzk",
    authDomain: "hypotec-lo-campus.firebaseapp.com",
    projectId: "hypotec-lo-campus",
    storageBucket: "hypotec-lo-campus.appspot.com",
    messagingSenderId: "511124128725",
    appId: "1:511124128725:web:1ba63cc4b6982a177b735c"
};

export default firebaseConfig;

//const app = initializeApp(firebaseConfig);