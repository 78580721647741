import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserAuthContext } from '../App';
import { useContext, useState } from 'react';
import firebase from 'firebase';
import Button from 'react-bootstrap/Button';

export default function Header() {
  const { user, setUser } = useContext(UserAuthContext);
  let history = useHistory();
  const LObenefitsClick = () => history.push('/LObenefits');
  const founderMessageClick = () => history.push('/founderMessage');
  const licensingClick = () => history.push('/licensing');

  const [show, setShow] = useState(false);

  const location = useLocation();

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    number: '',
    resume: '',
    resumeName: '',
    referer: '',
    licensed: '',
  };

  const [eachEntry, setEachEntry] = useState(initialState);
  let {
    firstName,
    lastName,
    email,
    number,
    resume,
    resumeName,
    referer,
    licensed,
  } = eachEntry;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function signOut() {
    setUser(null);
    localStorage.removeItem('user');
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        alert('Unsuccessful Sign out');
      });
  }

  const handleOnChange = (e) => {
    //eachEntry[e.target.name] = e.target.value
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const onFileChange = async (e) => {
    // Update the state
    let file = e.target.files[0];
     let filename = '' + file.name;
    let fr = new FileReader();
    fr.onloadend = ResumeLoaded;
    fr.readAsDataURL(file);

    setEachEntry({ ...eachEntry, resumeName: filename });
    // resumeName = filename;
  };
  const ResumeLoaded = (fr) => {
    const resumeBase64 = fr.target.result;
    setEachEntry({ ...eachEntry, resume: resumeBase64 });
  };

  const handleResumeSubmit = (e) => {
    e.preventDefault();
//console.log("handleResumeSubmit")
     eachEntry.resumeName = document.getElementById('Resume').files[0].name;
    fetch('https://hypotec-lo-campus-api.web.app/sendResume', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.');

          handleClose();
        } else if (response.status === 'fail') {
          alert('Message failed to send.');
        }
      });
  };
  return (
    <>
      {location.pathname !== '/required' && (
        <Navbar className="navbar" bg="light" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {!user ? (
            <Link to={`/`}>
              <img
                src="/Hypoteclogo.png"
                alt="Hypotec a new way of lending"
                className="logo"
              />
            </Link>
          ) : (
            <Link to={`/employee`}>
              <img
                src="/Hypoteclogo.png"
                alt="Hypotec a new way of lending"
                className="logo"
              />
            </Link>
          )}
          <br />
          <Navbar.Collapse id="basic-navbar-nav">
            {!user && (
              <Button onClick={(e) => LObenefitsClick(e)} className="link">
                Loan Officer Benefits
              </Button>
            )}
            <br />
            {!user && (
              <Button
                //href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/New%20LO%20presentation.pdf?alt=media&token=70c902cc-b26c-4aaa-b9b2-dc5832231bbd"
                href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/loBenefits%2FNew%20LO%20presentation%2005%2016%2023.pdf?alt=media&token=a0f69ff2-4d77-461e-81e2-acaba188b55d"
                className="link"
                target="_blank"
              >
                Presentation
              </Button>
            )}
            <br />
            {!user && (
              <Button onClick={(e) => founderMessageClick(e)} className="link">
                Founder's Message
              </Button>
            )}
            <br />
            {!user && (
              <Button onClick={(e) => licensingClick(e)} className="link">
                Get Licensed
              </Button>
            )}
          </Navbar.Collapse>
          <br />
          <div className="joinTeam">
            {!user ? (
              <Button onClick={handleShow} className="Btn">
                Join Our Team
              </Button>
            ) : (
              <Button
                style={{ marginRight: '50px' }}
                onClick={(e) => signOut(e)}
                className="Btn"
              >
                Sign Out
              </Button>
            )}
          </div>
          <Modal show={show} onHide={handleClose} className="resumeModal">
            <Modal.Header className="modal-title" closeButton closeLabel="">
              <Modal.Title>Join Our Team</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <Form style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    onChange={handleOnChange}
                    value={firstName}
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    onChange={handleOnChange}
                    value={lastName}
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Primary Phone</Form.Label>
                  <Form.Control
                    name="number"
                    type="num"
                    onChange={handleOnChange}
                    value={number}
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    onChange={handleOnChange}
                    value={email}
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>
                    Were you referred by a Loan Officer at our company? If so,
                    please provide their name
                  </Form.Label>
                  <Form.Control
                    name="referer"
                    type="text"
                    onChange={handleOnChange}
                    value={referer}
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>What state(s) are you licensed in?</Form.Label>
                  <Form.Control
                    name="licensed"
                    type="text"
                    onChange={handleOnChange}
                    value={licensed}
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Upload Resume</Form.Label>
                  <Form.Control
                    name="resumeName"
                    type="file"
                    id="Resume"
                    onChange={onFileChange}
                  />
                </Form.Group>

                <Button
                  style={{ margin: '10px' }}
                  variant="primary"
                  type="submit"
                  onClick={handleResumeSubmit}
                >
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Navbar>
      )}
    </>
  );
}
