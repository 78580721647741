import GoToTop from '../../Components/GoToTop'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'

export default function CreditBoost() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const clickRescore = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Rescore' })
    handleShow()
  }

  const initialState = {
    to: '',
    name: '',
    email: '',
    messageBody: '',
    files: [],
  }

  const [eachEntry, setEachEntry] = useState(initialState)
  let { name, email, messageBody, to, files } = eachEntry

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }
  
  
  const PDFLoaded = async (e) => {
    const fileInput = document.getElementById('PDF')
    // Convert the FileList into an array and iterate
    let files = Array.from(fileInput.files).map((file) => {
      // Define a new file reader
      let reader = new FileReader()
      
      // Create a new promise
      return new Promise((resolve) => {
        // Resolve the promise after reading file
        reader.onload = () => resolve({ filebase64: reader.result })
        
        // Reade the file as a text
        reader.readAsDataURL(file)
      })
    })
    // At this point you'll have an array of results
    const fileArray = await Promise.all(files)
    for (let i = 0; i < fileArray.length; i++) {
      fileArray[i].fileName = fileInput.files[i].name
      fileArray[i].fileType = fileInput.files[i].type
    }
    setEachEntry({ ...eachEntry, files: fileArray })
  }
  const handlePDFSubmit = (e) => {
    e.preventDefault()
    fetch('https://hypotec-lo-campus-api.web.app/rescore', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.')
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form
          
          handleClose()
        } else if (response.status === 'fail') {
          alert('Message failed to send.')
        }
      })
  }

  return (
    <div className="creditBoostPage">
      <div>
        <h2>
          Credit Score <span style={{ color: '#009ee5' }}>Boost</span>
        </h2>
        <Button className="Btn" onClick={clickRescore}>
          Credit Rescore Request?
        </Button>
      </div>
      <div>
        <Modal animation={false} show={show} onHide={handleClose} className="PDFModal">
          <Modal.Header className="modal-title" closeButton closeLabel="">
            <Modal.Title>{`Request a ${to}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <Form style={{ display: 'flex', flexWrap: 'wrap' }}>
              <p>
              Please note all request have a 48 hour turnaround time.
              </p>
              <Form.Group style={{ padding: '10px' }}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  onChange={handleOnChange}
                  value={name}
                />
              </Form.Group>
              <Form.Group style={{ padding: '10px', width:'50%' }}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={handleOnChange}
                  value={email}
                />
              </Form.Group>
              <Form.Group style={{ padding: '10px', width: '100%' }} className="mb-3">
                <Form.Label>Tell us what you want to accomplish</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="messageBody"
                  type="text"
                  value={messageBody}
                  onChange={handleOnChange}
                />
              </Form.Group>
              <Form.Group style={{ padding: '10px' }}>
                <Form.Label>
                  Please upload the credit report and any supporting docs here.
                </Form.Label>
                <Form.Control
                  type="file"
                  id="PDF"
                  multiple
                  onChange={PDFLoaded}
                />
              </Form.Group>

              <Button
                style={{ margin: '10px' }}
                variant="primary"
                type="submit"
                onClick={handlePDFSubmit}
              >
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <GoToTop />
    </div>
  )
}
