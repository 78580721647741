import { useContext, useState, useEffect } from 'react';
import { UserAuthContext } from '../../App';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router';
import GoToTop from '../../Components/GoToTop';

export default function Required() {
  const { user } = useContext(UserAuthContext);
  const [videosSeen, setVideosSeen] = useState(0);
  const history = useHistory();
  let interval = {};

  const employeeClick = () => {
    const updateUser = { email: user.email, complianceVideosWatched: 5 };

    fetch(`https://hypotec-lo-campus-api.web.app/users/${user.email}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(updateUser),
    })
      .then((response) => response.json())
      .then(() => {
        clearInterval(interval);
        history.push('/employee');
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    window.confirm(
      'Compliance videos will begin shortly. If all videos are not watched all the way through, then the next time you sign in, the videos will begin again from the beginning.'
    );
    // run this only one
    interval = setInterval(() => {
      // every 2 minutes bother the user
      let timer = setTimeout(() => {
        // if the user doesn't reply within 30sec stop the ideo
        document.getElementById('compliance').pause();
      }, 30 * 1000);

      if (!document.getElementById('compliance')) {
        clearInterval(interval);
        return;
      }

      if (window.confirm('Are you still watching?') === true) {
        clearTimeout(timer);
        document.getElementById('compliance').play();
      } else {
        clearTimeout(timer);
        document.getElementById('compliance').play();
      }
    }, 2 * 60 * 1000);
  }, []);

  return (
    <div className="requiredPage">
      <div className="requiredHeaders">
        <h1>
          Welcome{' '}
          <span style={{ color: '#009ee5' }}>
            {user ? user.firstName : 'Guest'}!
          </span>
        </h1>
        <h6>
          We are excited to have you part of our growing family and in helping
          you grow your book of business. Before accessing the complete benefits
          our platform has to offer, please take a moment to watch the following
          videos.
        </h6>
      </div>
      {videosSeen === 0 && (
        <div>
          <video
            id="compliance"
            width="600"
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FNewHireOrientation.mp4?alt=media&token=3d7d8e18-89d4-4a58-b738-8299af13b55f"
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-31%20at%202.07.45%20PM.png?alt=media&token=1943c216-8201-4b7c-871f-2b0817065fca"
            autoPlay
            onEnded={() => setVideosSeen(1)}
          ></video>
        </div>
      )}
      {videosSeen === 1 && (
        <div>
          <video
            id="compliance"
            width="600"
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-AML.mp4?alt=media&token=bae8316f-374d-4c5f-b915-5ec83120f348"
            autoPlay
            onEnded={() => setVideosSeen(2)}
          ></video>
        </div>
      )}
      {videosSeen === 2 && (
        <video
          id="compliance"
          width="600"
          src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-GLB.mp4?alt=media&token=53eced46-febc-4db6-8a9d-508219e3a9ae"
          autoPlay
          onEnded={() => setVideosSeen(3)}
        ></video>
      )}
      {videosSeen === 3 && (
        <div>
          <video
            id="compliance"
            width="600"
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-RedFlag.mp4?alt=media&token=2c43fdc3-99ff-448b-ab09-165354e135ab"
            autoPlay
            onEnded={() => setVideosSeen(4)}
          ></video>
        </div>
      )}
      {videosSeen === 4 && (
        <div id="Final_page">
          <video
            id="compliance"
            width="600"
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-FairLendingTraining.mp4?alt=media&token=b1fe2477-d24d-492a-a6cb-c206b2a935ae"
            autoPlay
            onEnded={() => setVideosSeen(5)}
          ></video>
        </div>
      )}
      {videosSeen === 5 && (
        <Button
          className="Btn"
          variant="primary"
          style={{
            marginRight: '30%',
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
          onClick={employeeClick}
        >
          <h3>To the Platform</h3>
        </Button>
      )}
      <GoToTop />
    </div>
  );
}
