import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import GoToTop from '../../Components/GoToTop';

export default function PrintDesignTemps() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clickSarah = (e) => {
    e.preventDefault();
    setEachEntry({ ...eachEntry, to: 'Sarah' });
    handleShow();
  };

  const initialState = {
    to: '',
    name: '',
    email: '',
    messageBody: '',
    files: [],
  };
  const [eachEntry, setEachEntry] = useState(initialState);
  let { name, email, messageBody, to, file } = eachEntry; //need to change here?

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value });
  };

  const ImageLoaded = async (e) => {
    const fileInput = document.getElementById('image');

    // Convert the FileList into an array and iterate
    let files = Array.from(fileInput.files).map((file) => {
      // Define a new filefilen reader
      let reader = new FileReader();

      // Create a new promise
      return new Promise((resolve) => {
        // Resolve the promise after reading file

        reader.onload = () => resolve({ filebase64: reader.result });

        // Reade the file as a text
        reader.readAsDataURL(file);
      });
    });

    // At this point you'll have an array of results
    const fileArray = await Promise.all(files);
    for (let i = 0; i < fileArray.length; i++) {
      fileArray[i].fileName = fileInput.files[i].name;
      fileArray[i].fileType = fileInput.files[i].type;
    }
    setEachEntry({ ...eachEntry, files: fileArray });
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();

    fetch('https://hypotec-lo-campus-api.web.app/customDesign', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.');
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form

          handleClose();
        } else if (response.status === 'fail') {
          alert('Message failed to send.');
        }
      });
  };

  return (
    <div className="printDesignTempsPage">
      <div>
        <h2 style={{ marginLeft: '5px' }}>
          Print Design <span style={{ color: '#009ee5' }}>Templates</span>
        </h2>
        <p style={{ marginLeft: '5px' }}>
          Hypotec offers all loan advisors exclusive <br /> access to our custom
          design studio. Click <br /> below for an in-depth library of design
          assets <br /> to use on print media.
        </p>
        <Button
          className="Btn"
          href="https://hypotecdesignstudio.com/"
          target="_blank"
        >
          Design Studio
        </Button>
        <p style={{ marginLeft: '5px', marginTop: '15px' }}>
          Can’t find what you’re looking for? <br />
        </p>
        <Button className="Btn" onClick={clickSarah}>
          Request a custom design.
        </Button>
      </div>
      <div className="printDropdowns">
        {/* <Dropdown>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Business Cards
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light" className="dropdown-menu">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FBusinessCard.pdf?alt=media&token=2bda067b-fbe2-4ec9-b432-ba67307e2a3a"
              target="_blank"
            >
              Business Card
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        {/* <Dropdown>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Post Cards
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FThankYouCard.pdf?alt=media&token=e9e49e65-c608-43c8-ade8-757c0956eeda"
              target="_blank"
            >
              Thank You Card
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FEditablePostcard1.pdf?alt=media&token=8bed44e5-d396-4281-b48f-635fa2b030e6"
              target="_blank"
            >
              Editable Post Card 1
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FEditablePostcard2.pdf?alt=media&token=3af18441-fbfb-49e7-9b13-eaaf7bd10413"
              target="_blank"
            >
              Editable Post Card 2
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FEditablePostcard3.pdf?alt=media&token=48d3bb08-4d2d-4227-81ca-b74a4d80c906"
              target="_blank"
            >
              Editable Post Card 3
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <Dropdown>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Yard Signs
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FEditableYardSign1(18x12in).pdf?alt=media&token=7c70c2e9-59f8-48e2-ac4a-6946cbc6c6a4"
              target="_blank"
            >
              Editable Yard Sign 18 x 12in
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FEditableYardSign2(18x12in).pdf?alt=media&token=9f5246ae-381a-4887-a927-bd76f4563686"
              target="_blank"
            >
              Editable Yard Sign 18 x 12in (2)
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FEditableYardSign3(18x12in).pdf?alt=media&token=10cc1129-f6ca-41d7-82cc-4090c64e486b"
              target="_blank"
            >
              Editable Yard Sign 18 x 12in (3)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Dropdown>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Flyers
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FGeneralFlyer1.pdf?alt=media&token=1aff09c6-da58-4b3c-b06b-dd89b2356aa3"
              target="_blank"
            >
              General Flyer 1
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FGeneralFlyer2.pdf?alt=media&token=080d02d5-e56b-4e61-894a-e2204ad7f1a7"
              target="_blank"
            >
              General Flyer 2
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2Floan%20options_%20one%20sheet.pdf?alt=media&token=2eeaedf6-15a7-4aec-80c4-e1adb252726f"
              target="_blank"
            >
              Loan Options One Sheet
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNew%20Mortgage%20Products%201.pdf?alt=media&token=aae40ab5-1a59-44e8-b2d7-ebea45d6f26c"
              target="_blank"
            >
              New Mortgage Products 1
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNew%20Mortgage%20Products%20(2).pdf?alt=media&token=4d598a6b-b22e-4061-a100-d7355219c097"
              target="_blank"
            >
              New Mortgage Products 2
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Dropdown>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Presentations
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNew%20realtor%20presentation.pdf?alt=media&token=739830c3-d977-455d-ae43-b32b0c8c7d26"
              target="_blank"
            >
              Realtor Presentation
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNEW%20realtor%20presentation.pptx?alt=media&token=fefd36f1-a709-4210-950a-12e0b225bef6"
              target="_blank"
            >
              Realtor Presentation PowerPoint
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNEW%20financial%20advisor%20presentation.pdf?alt=media&token=88385029-3dce-4d8c-9881-6cecad4dcf5f"
              target="_blank"
            >
              Financial Advisor Presentation
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNEW%20financial%20advisor%20presentation.pptx?alt=media&token=8d59adfa-0a77-4526-8fba-c19459eaed42"
              target="_blank"
            >
              Financial Advisor PowerPoint
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/printDesignTemps%2FNEW%20realtor%20presentation.pptx?alt=media&token=fefd36f1-a709-4210-950a-12e0b225bef6"
              target="_blank"
            >
              Realtor PowerPoint
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Modal show={show} onHide={handleClose} className="messageModal">
        <Modal.Header className="modal-title" closeButton closeLabel="">
          <Modal.Title>{`Custom Design Request to ${to}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <p>
              Please note all custom design requests have a 72 hour turn around
              time.
            </p>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                onChange={handleOnChange}
                value={name}
              />
            </Form.Group>
            {/* <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Primary Phone</Form.Label>
              <Form.Control
                name="number"
                type="num"
                onChange={handleOnChange}
                value={number}
              />
            </Form.Group> */}
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                onChange={handleOnChange}
                value={email}
              />
            </Form.Group>
            <Form.Group
              style={{ padding: '10px' }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Your Custom Design</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="messageBody"
                type="text"
                value={messageBody}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Form.Group style={{ padding: '10px' }} className="mb-3">
              <Form.Label>
                Please upload any images you want used in your design:
              </Form.Label>
              <Form.Control
                type="file"
                id="image"
                multiple
                onChange={ImageLoaded}
              />
            </Form.Group>
            <Button
              style={{ margin: '10px' }}
              variant="primary"
              type="submit"
              onClick={handleImageSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <GoToTop />
    </div>
  );
}
