import GoToTop from "../../Components/GoToTop"

export default function BuildingBusiness() {
  return (
    <div className="buildingBusinessPage">
      <div className="buildingHeaders">
        <h2>
          Building my <span style={{ color: '#009ee5' }}>Business</span>
        </h2>
        <h6>
          At Hypotec we're here to help you grow your business quickly and
          efficiently.
        </h6>
      </div>
      <div className="videoContainer">
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%203.56.21%20PM.png?alt=media&token=06ec63a5-acdc-4dcb-ab33-3469a1cf4945"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddySalesTrainingFundamentals.mp4?alt=media&token=70cfea84-c9d7-4e0e-af50-f436fe63827b"
              type="video/mp4"
            />
          </video>
        </div>
        <br />
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%203.57.01%20PM.png?alt=media&token=61c27d6d-d688-43b2-a2b7-3d2a56cf13bb"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-MortgageSalesFundamentals.mp4?alt=media&token=86354794-a4da-47df-bc0e-d845da32a505"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
