import GoToTop from '../../Components/GoToTop'

export default function GovProducts() {
  return (
    <div className="govProductsPage">
      <h2>
        Government <span style={{ color: '#009ee5' }}>Products</span>
      </h2>
      <div className="govProducts">
        <div style={{ width: '50%' }}>
          <h5>FHA</h5>
          <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 580</li>
          <li style={{ fontWeight: 'bold' }}>PURCHASE LTV - UP TO 96.5%</li>
          <li style={{ fontWeight: 'bold' }}>
            RATE AND TERM REFINANCE LTV – UP TO 97.75%
          </li>
          <li style={{ fontWeight: 'bold' }}>
            CASH OUT REFINANCE LTV – 80% MAX
          </li>
          <li style={{ fontWeight: 'bold' }}>DTI UP TO 46.99% / 56.99%</li>
        </div>
        <br />
        <div style={{ width: '50%' }}>
          <h5>VA</h5>
          <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 580</li>
          <li style={{ fontWeight: 'bold' }}>
            PURCHASE LTV - UP TO 100% (NOT INCLUDING VA FUINDING FEE)
          </li>
          <li style={{ fontWeight: 'bold' }}>
            RATE AND TERM REFINANCE LTV – UP TO 100% (INCLUDING FUNDING FEE)
          </li>
          <li style={{ fontWeight: 'bold' }}>
            CASH OUT REFINANCE LTV – 90% MAX (INCLUDING FUNDING FEE)
          </li>
          <li style={{ fontWeight: 'bold' }}>NO FICO VA IRRRLS AVAILABLE </li>
          <li style={{ fontWeight: 'bold' }}>
            DTI UP TO 45% / NO MAX (RUN DU AND SEE WHAT THE FINDINGS SAY)
          </li>
        </div>
        <br />
        <div style={{ width: '50%' }}>
          <h5>USDA</h5>
          <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 580</li>
          <li style={{ fontWeight: 'bold' }}>PURCHASE LTV - UP TO 100%</li>
          <li style={{ fontWeight: 'bold' }}>
            RATE AND TERM REFINANCE LTV – UP TO 97%
          </li>
          <li style={{ fontWeight: 'bold' }}>
            CASH OUT REFINANCE LTV – 80% MAX
          </li>
          <li style={{ fontWeight: 'bold' }}>DTI UP TO 50%</li>
        </div>
        <br />
        <div style={{ width: '50%', marginTop: '20px' }}>
          <h5>REVERSE MORTGAGES</h5>
          <li style={{ fontWeight: 'bold' }}>
            FOR MORE INFORMATION PLEASE CONTACT LOAN OFFICER SUPPORT
          </li>
          {/* <li style={{ fontWeight: 'bold' }}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          </li>
          <li style={{ fontWeight: 'bold' }}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          </li>
          <li style={{ fontWeight: 'bold' }}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          </li> */}
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
