import Button from 'react-bootstrap/Button';
// import firebase from 'firebase';
export default function Token() {
//   firebase
//     .auth()
//     .signInWithCustomToken(token)
//     .then((userCredential) => {
//       // Signed in
//       var user = userCredential.user;
//       // ...
//     })
//     .catch((error) => {
//       var errorCode = error.code;
//       var errorMessage = error.message;
//       // ...
//     });

  return (
    <section
      style={{ height: '400px', backgroundColor: 'white', textAlign: 'center' }}
    >
      <input type="num" />
      <Button className="Btn">
        <h3>Token</h3>
      </Button>
    </section>
  );
}
