import GoToTop from '../../Components/GoToTop'

export default function BridgeLoans() {
  return (
    <div className="bridgeLoansPage">
      <h2>
        Bridge <span style={{ color: '#009ee5' }}>Loans</span>
      </h2>
      <div>
        <br />
        <li style={{ fontWeight: 'bold' }}>QUICK CLOSING</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>RATES BETWEEN 7.99% AND 9.99%</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          LTV’S BETWEEN 50% - 70% ALL DEPENDING ON DEAL
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          12 MONTH TERM WITH OPTION TO EXTEND
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          SOME LENDERS HAVE PREPAYMENT PENALTIES, OTHERS DO NOT
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          CLOSING UNDER LLC OR CORP IS USUALLY MANDATORY
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          TYPICALLY USED FOR INVESTMENT PROPERTIES and 2ND HOMES
        </li>
        <br />
        {/* <li style={{ fontWeight: 'bold' }}>
          SOME LENDERS WILL ALLOW ON OWNER OCCUPIED PROPERTIES BUT RARE ON THESE
          DEALS{' '}
        </li> */}
      </div>
      <GoToTop />
    </div>
  )
}
