import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function Technology() {
  let history = useHistory()
  const loanPush = () => history.push('/loanOriginator')
  const pricingEnginePush = () => history.push('/pricingEngine')
  const CRMPush = () => history.push('/crm')

  return (
    <div className="technologyPage">
      <div style={{ flexDirection: 'column', textAlign: 'left' }}>
        <h2 style={{ textAlign: 'left' }}>
          Technology <span style={{ color: '#009ee5' }}>Resources</span>
        </h2>
        <h6>Didn't find what you are looking for?</h6>
        <Button
          className="Btn"
          variant="primary"
          href="https://us02web.zoom.us/j/8926820302"
          target="_blank"
        >
          Click Here for Support
        </Button>
      </div>

      <Button
        variant="primary"
        className="Btn"
        onClick={loanPush}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Loan Originator Software</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={pricingEnginePush}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Pricing Engine</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={CRMPush}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>CRM</h3>
      </Button>
      <GoToTop/>
    </div>
  )
}
