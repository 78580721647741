import GoToTop from "../../Components/GoToTop"

export default function PricingEngine() {
  return (
    <div className="pricingEnginePage">
      <h2>
        Pricing <span style={{ color: '#009ee5' }}>Engine</span>
      </h2>
      <div className="videoContainer">
      <div className="videos">
        <video
          controls
          poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddyPricingEngine.png?alt=media&token=6a52d687-9132-4dd2-843a-43b4aa74dc24"
          width="600"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddyPricingEngine.mp4?alt=media&token=6afe1ab9-87a0-462b-945f-fa3fa4449614"
            type="video/mp4"
          />
        </video>
      </div>
      </div>
      <GoToTop/>
    </div>
  )
}
