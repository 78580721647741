import Dropdown from 'react-bootstrap/Dropdown'
import GoToTop from '../../Components/GoToTop'
import { useHistory } from 'react-router-dom'

export default function Worksheets() {
  let history = useHistory()
  const creditBoostClick = () => history.push('/creditBoost')

  return (
    <div className="worksheetsPage">
      <h2>
        Worksheets & <span style={{ color: '#009ee5' }}>Tools</span>
      </h2>
      <Dropdown>
        <Dropdown.Toggle variant="light" className="dropdown" size="lg">
          Worksheets
        </Dropdown.Toggle>
        <Dropdown.Menu variant="light">
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FLoanComparisonSummary.xlsx?alt=media&token=9285eb3a-6767-4a8d-8280-e2fd4e442463"
            target="_blank"
            active
          >
            Loan Comparison Summary
          </Dropdown.Item>
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FSingleLoanSummary.xlsx?alt=media&token=3a51ab26-05d6-4970-b689-142f1c9796d7"
            target="_blank"
          >
            Single Loan Summary
          </Dropdown.Item>
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FIncome%20Worksheet.xlsx?alt=media&token=13604d55-d212-4dc8-a450-853858fb535d"
            target="_blank"
          >
            Income Worksheet
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle variant="light" className="dropdown" size="lg">
          Tools
        </Dropdown.Toggle>
        <Dropdown.Menu variant="light">
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FAmortizationCalc.xls?alt=media&token=89273ad8-652a-49e1-9741-37d142fe6dc7"
            target="_blank"
            active
          >
            Amortization Calculator
          </Dropdown.Item>
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FSubmissionDocChecklist.pdf?alt=media&token=4a8f106d-768e-4fa3-865a-a6f8d512b748"
            target="_blank"
          >
            Submission Document Checklist
          </Dropdown.Item>
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FIncomeWorksheet.xlsx?alt=media&token=e24ccecb-a815-40e6-94c6-155730fdfe2b"
            target="_blank"
          >
            Income Calculator
          </Dropdown.Item>
          <Dropdown.Item
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/worksheets%26tools%2FBank%20Statement%20Income%20Calc.xlsx?alt=media&token=e389b07a-f431-4ad8-a43f-eb2bd364bc9a"
            target="_blank"
          >
            Bank Statement Income Calculator
          </Dropdown.Item>
          <Dropdown.Item
            onClick={creditBoostClick}
          >
            Credit Score Boost
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <GoToTop />
    </div>
  )
}
