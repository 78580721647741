import Container from 'react-bootstrap/Container'
import { Link, useLocation } from 'react-router-dom'

export default function Footer() {
  const location = useLocation()
  return (
    <>
      {location.pathname !== '/required' && (
        <Container fluid className="footer">
          <div>
            <div className="row">
              <div className="col-md-4 col-lg-3 col-xl-3">
                <div className="logo-side">
                  <img
                    src="/Hypoteclogo.png"
                    className="logo"
                    alt="Company logo"
                  />
                  <p style={{ paddingLeft: '60px' }}>
                    Empowering through education and technology.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3">
                <div className="other-links">
                  <h5>Contact Us</h5>
                  <hr className="underline" />
                  <ul>
                    <li>
                      <a href="mailto:info@hypotec.com">info@hypotec.com</a>
                    </li>
                    <li>
                      <a href="tel: 844,497,6832">844.497.6832</a>
                    </li>
                    <li>
                      <h4 className="pt-2">Hypotec | Florida</h4>
                      <p>
                        20283 State Road 7, <br />
                        Suite 429 <br />
                        Boca Raton, <br />
                        FL 33498
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3">
                <div className="other-links">
                  <h5>Company</h5>
                  <hr className="underline" />
                  <ul>
                    <li>
                      <Link to="/stateLicensing">State Licensing</Link>
                    </li>
                    <li>
                      <a
                        href="https://stage.hypotec.com/Aboutus/Careers"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Careers
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3">
                <div className="other-links">
                  <h5>Support</h5>
                  <hr className="underline" />
                  <ul>
                    <li>
                      <Link to="/privacyPolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/contactUs">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-text">
            <div className="row">
              <div className="col-md-12">
                <div className="bottom-other-links">
                  <p>
                    <a
                      href="https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/331734"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingLeft: '50px' }}
                    >
                      NMLS ID: 331734
                    </a>
                  </p>
                  <p style={{ paddingLeft: '50px' }} className="m-0">
                    2020 Hypotec, All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
