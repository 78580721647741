import GoToTop from '../../Components/GoToTop';

export default function LinkedinBanners() {
  return (
    <div className="linkedinBanners">
      <h2>
        LinkedIn <span style={{ color: '#009ee5' }}>Banners</span>
      </h2>
      <div className="gallery">
        <div>
          <button className="longContainer">
            <a href="/assets/LinkedInBanner1.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FlinkedInBanner1.jpg?alt=media&token=66b9d011-6958-4304-94ff-50c84872e71b"
                alt=" "
                className="image"
                style={{ width: '400px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="longContainer">
            <a href="/assets/LinkedInBanner2.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FLinkedInBanner2.jpg?alt=media&token=35b80098-8c0e-41db-993d-15baf28b07d5"
                alt=" "
                className="image"
                style={{ width: '400px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="longContainer">
            <a href="/assets/LinkedInBanner3.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/linkedInBanners%2FLinkedInBanner3.jpg?alt=media&token=24dc877b-9650-4d12-9db8-217ee5e7c93e"
                alt=" "
                className="image"
                style={{ width: '400px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <GoToTop />
      </div>
    </div>
  );
}
