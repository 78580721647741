import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function Resource() {
  const history = useHistory()
  const handleClick = () => history.push('/marketing')
  const buttonClick = () => history.push('/hr')
  const click = () => history.push('/products')
  const push = () => history.push('/LOTools')
  const handlePush = () => history.push('/sales')
  const buttonPush = () => history.push('/technology')
  /* const marketingTemplates = () => history.push('/marketingTemplates') */

  return (
    <>
      <div className="resourcePage">
        <div className="resourceHeaders">
          <h1>
            Resource <span style={{ color: '#009ee5' }}> Center</span>
          </h1>
          <h6>
            At Hypotec, it’s all about how far you can take your career. We
            build our team up by ensuring you have all of the top resources,
            information and training tools to succeed. Our Resource Center is
            equipped with industry leading knowledge to help take you to the
            next level.
          </h6>
        </div>
        <div className="resourceBtns">
          <Button
            variant="primary"
            className="Btn"
            onClick={handlePush}
            style={{
              borderRadius: '50%',
              width: '225px',
              height: '225px',
              border: '1px #0177b5',
              flexDirection: 'row',
            }}
          >
            <h2>Sales</h2>
          </Button>
          <Button
            variant="primary"
            className="Btn"
            onClick={handleClick}
            style={{
              borderRadius: '50%',
              width: '225px',
              height: '225px',
              border: '1px #0177b5',
              flexDirection: 'row',
            }}
          >
            <h2>Marketing</h2>
          </Button>
          <Button
            variant="primary"
            className="Btn"
            onClick={push}
            style={{
              borderRadius: '50%',
              width: '225px',
              height: '225px',
              border: '1px #0177b5',
              flexDirection: 'row',
            }}
          >
            <h2>Loan Officer Tools</h2>
          </Button>
          <Button
            variant="primary"
            className="Btn"
            onClick={click}
            style={{
              borderRadius: '50%',
              width: '225px',
              height: '225px',
              flexDirection: 'row',
              border: '1px #0177b5',
            }}
          >
            <h2>Mortgage Products</h2>
          </Button>
          <Button
            variant="primary"
            className="Btn"
            onClick={buttonPush}
            style={{
              borderRadius: '50%',
              width: '225px',
              height: '225px',
              border: '1px #0177b5',
              flexDirection: 'row',
            }}
          >
            <h2>Technology</h2>
          </Button>
          <Button
            variant="primary"
            className="Btn"
            onClick={buttonClick}
            style={{
              borderRadius: '50%',
              width: '225px',
              height: '225px',
              border: '1px #0177b5',
              flexDirection: 'row',
            }}
          >
            <h2>Human Resources</h2>
          </Button>
                  {/* }        <Button
                      variant="primary"
                      className="Btn"
                      onClick={marketingTemplates}
                      style={{
                          borderRadius: '50%',
                          width: '225px',
                          height: '225px',
                          border: '1px #0177b5',
                          flexDirection: 'row',
                      }}
                  >
                      <h2>Marketing Templates</h2>
                  </Button>*/}
        </div>
        <GoToTop/>
      </div>
    </>
  )
}
