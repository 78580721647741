import GoToTop from '../../Components/GoToTop'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'

export default function ContactUs() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const clickFreddy = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Freddy' })
    handleShow()
  }

  const clickBenny = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Benny' })
    handleShow()
  }
  const clickSarah = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Sarah' })
    handleShow()
  }
  const clickDanielle = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Danielle' })
    handleShow()
  }
  const initialState = {
    to: '',
    name: '',
    email: '',
    number: '',
    messageBody: '',
  }
  const [eachEntry, setEachEntry] = useState(initialState)
  let { name, email, number, messageBody, to } = eachEntry

  const handleOnChange = (e) => {
    //eachEntry[e.target.name] = e.target.value
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    fetch('https://hypotec-lo-campus-api.web.app/sendMessage', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.')
          handleClose()
        } else if (response.status === 'fail') {
          alert('Message failed to send.')
        }
      })
  }

  return (
    <div className="contactUsPage">
      <Container fluid className="ContactUsContainer">
        <div className="row">
          <div className="col-md-6">
            <div className="contactusImage">
              <Image
                src="/assets/contact-us.jpg"
                roundedCircle="true"
                className="img-fluid"
                alt="3 ways to contact finger touching a phone symbol"
              />
            </div>
          </div>
          <div className="col-md-6 p-0">
            <div className="contactusContent">
              <div className="all-caption contactText text-center">
                <h1 style={{ fontWeight: 'bold', fontSize: '4rem' }}>
                  Contact <span style={{ color: '#009ee5' }}>Us</span>
                </h1>
                <p style={{ color: 'gray' }}>
                  Hypotec is here for you. Never hesitate to reach out to us
                  with questions or concerns.
                </p>
                <br />
                <p className="black-text">
                  <strong>Operating Hours:</strong>
                </p>
                <div className="row timeNoMail d-flex justify-content-center">
                  <div className="col-md-4 col-6 offset-lg-1 offset-xl-2 p-0">
                    <div className="text-left">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Monday – Friday </p>
                        <small>:</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 p-0">
                    <div className="text-left">
                      <p className="black-text">9:00AM EST – 7:00PM EST</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 offset-lg-1 offset-xl-2 p-0">
                    <div className="text-left">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Toll-Free Calls</p>
                        <small>:</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 p-0">
                    <div className="text-left">
                      <a href="tel:+844-497-6832">
                        <p className="black-text">844.497.6832</p>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4 col-6 offset-lg-1 offset-xl-2 p-0">
                    <div className="text-left">
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Customer Care</p>
                        <small>:</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 p-0">
                    <div className="text-left">
                      <p className="black-text"> info@hypotec.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="all-caption contactText text-center p-0">
              <div className="freddyInfo ">
                <div className="d-flex">
                  <Image
                    src="/assets/freddy-headshot.png"
                    alt="founder Freddy Abitbol headshot"
                    className="img-fluid"
                  />
                  <div>
                    <h5>Freddy Abitbol</h5>
                    <small>
                      <strong>Contact the CEO</strong>
                    </small>
                    <div className="textbtnarea">
                      <p>
                        At Hypotec, we’re an open book. CEO and President Freddy
                        Abitbol welcomes any and all feedback or questions you
                        may have regarding your mortgage.
                      </p>
                      <div className="row timeNoMail">
                        <div className="col-md-12 col-12 pt-2 px-0">
                          <div className="d-flex freddycliktext">
                            <Button className="Btn" onClick={clickFreddy}>
                              Message
                            </Button>
                            <Button href="tel:+844-497-6832" className="Btn">
                              Call
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="contacts">
        <div className="danielleCard">
          <h6 style={{ padding: '5px', color: '#0393d6' }}>
            CAREER OPPORTUNITIES
          </h6>
          <Image
            src="/assets/Danielle-Toomey.png"
            alt="HR Danielle Toomey headshot"
            className="danielleImage"
          />
          <h6 style={{ padding: '5px', color: '#0393d6' }}>DANIELLE TOOMEY</h6>
          <Button
            className="Btn"
            style={{ width: '200px' }}
            onClick={clickDanielle}
          >
            Message
          </Button>
        </div>
        <div className="sarahCard">
          <h6 style={{ padding: '5px', color: '#0393d6' }}>
            MARKETING/PR INQUIRIES
          </h6>
          <Image
            src="/assets/Sarah.png"
            alt="marketing Sarah Creegan headshot"
            className="img-fluid"
          />
          <h6 style={{ padding: '5px', color: '#0393d6' }}>SARAH CREEGAN</h6>
          <Button
            className="Btn"
            style={{ width: '200px' }}
            onClick={clickSarah}
          >
            Message
          </Button>
        </div>
        <div className="bennyCard">
          <h6 style={{ padding: '5px', color: '#0393d6' }}>TECHNICAL ISSUES</h6>
          <Image
            src="/assets/Ben.png"
            alt="technology Benny Perez headshot"
            className="img-fluid"
          />
          <h6 style={{ padding: '5px', color: '#0393d6' }}>BENNY PEREZ</h6>
          <Button
            className="Btn"
            style={{ width: '200px' }}
            onClick={clickBenny}
          >
            Message
          </Button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="messageModal">
        <Modal.Header className="modal-title" closeButton closeLabel="">
          <Modal.Title>{` Message to ${to}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                onChange={handleOnChange}
                value={name}
              />
            </Form.Group>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Primary Phone</Form.Label>
              <Form.Control
                name="number"
                type="num"
                onChange={handleOnChange}
                value={number}
              />
            </Form.Group>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                onChange={handleOnChange}
                value={email}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="messageBody"
                type="text"
                value={messageBody}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Button
              style={{ margin: '10px' }}
              variant="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <GoToTop />
    </div>
  )
}
