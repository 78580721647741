import GoToTop from '../../Components/GoToTop'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useState } from 'react'

export default function StateLicensing() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className="stateLicensingPage">
      <Container fluid className="mapContainer">
        <div className="row">
          <div className="col-md-7">
            <img src="/assets/h-map .png" alt="map of USA highlighting states Hypotec does business in" />
          </div>
        </div>
        <div className="col-md-5">
          <div className="all-caption stateLin-text">
            <h1
              style={{
                fontWeight: 'bold',
                marginLeft: '10%',
                fontSize: '4rem',
              }}
            >
              Where we do <br />
              <span style={{ color: '#009ee5' }}>business</span>
            </h1>
          </div>
        </div>
      </Container>
      <section className="rightLeftSection">
        <div className="row align-items-center">
          <div className="col-md-3">
            <div className="double-btnText text-center"></div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Alabama</h3>
                  <p className="m-0">Consumer Credit License</p>
                  <p className="m-0">License/Registration # 22881</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>California - DFPI</h3>
                  <p className="m-0">Finance Lenders Law License </p>
                  <p className="m-0">License/Registration #: 603D475</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>California – DRE</h3>
                  <p className="m-0">Real Estate Corporation License </p>
                  <p className="m-0">License/Registration #: 01857168</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Colorado</h3>
                  <p className="m-0">Mortgage Company Registration </p>
                  <p className="m-0">License/Registration #: 331734</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Georgia</h3>
                  <p className="m-0">Mortgage Lender </p>
                  <p className="m-0">License/Registration #: 59019</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>New Jersey</h3>
                  <p className="m-0">Residential Mortgage Lender license</p>
                  <p className="m-0">License/ registration #: 331734</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>New York</h3>
                  <p className="m-0">Mortgage Broker/Lender License</p>
                  <p className="m-0">License/Registration #: RMB 208877</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Texas</h3>
                  <p className="m-0">Mortgage Company License </p>
                  <p className="m-0">License/Registration #: 331734</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Florida</h3>
                  <p className="m-0">Mortgage Lender License </p>
                  <p className="m-0">License/Registration #: MLD69</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>North Carolina</h3>
                  <p className="m-0">Mortgage Lender License </p>
                  <p className="m-0">License/Registration #: L-170818</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Illinois</h3>
                  <p className="m-0">Residential Mortgage License</p>
                  <p className="m-0">License/Registration #: MB.6761594</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Maryland</h3>
                  <p className="m-0">Mortgage Lender License</p>
                  <p className="m-0">License/Registration #: 06-23074</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Michigan</h3>
                  <p className="m-0">1st Mortgage Broker/Lender License</p>
                  <p className="m-0">License/Registration #: FL0023557</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Ohio</h3>
                  <p className="m-0">
                    Residential Mortgage Lending Act Certificate of Registration
                  </p>
                  <p className="m-0">License/Registration #: RM.804690.000</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Pennsylvania</h3>
                  <p className="m-0">Mortgage Lender License</p>
                  <p className="m-0">License/Registration #: 90583</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Tennessee</h3>
                  <p className="m-0">Mortgage License</p>
                  <p className="m-0">License/Registration #: 331734</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Virginia</h3>
                  <p className="m-0">Broker License</p>
                  <p className="m-0">License/Registration #: MC-7224</p>
                </div>
              </div>
              <div className="col-md-6 col-12 col-lg-4 col-xl-4">
                <div className="addBoxLis">
                  <h3>Washington</h3>
                  <p className="m-0">Consumer Loan Company License</p>
                  <p className="m-0">License/Registration #: CL-331734</p>
                </div>
              </div>
              <div
                className="col-md-6 col-12 col-lg-4 col-xl-6"
                style={{ textAlign: 'center', marginLeft: '25%' }}
              >
                <div className="double-btnText">
                  <h3>State Licenses/Registrations</h3>
                  <a
                    href="https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/331734"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5>NMLS Consumer Access NMLS ID 331734</h5>
                  </a>
                </div>
                <Button
                  onClick={handleShow}
                  variant="link"
                  className="double-btnText"
                >
                  <h5>Consumer Complaint / Recovery Fund Notice</h5>
                </Button>
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                className="applyModal reqclbk"
              >
                <Modal.Header closeButton className="modal-title">
                  <Modal.Title> Figure: 7 TAC §80.200(b) </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">
                  <p>
                    "Consumers Wishing To File A Complaint Against A Company Or
                    A Residential Mortgage Loan Originator Should Complete And
                    Send A Complaint Form To The Texas Department Of Savings And
                    Mortgage Lending, 2601 North Lamar, Suite 201, Austin, Texas
                    78705. Complaint Forms And Instructions May Be Obtained From
                    The Department’s Website At Www.sml.texas.gov. A Toll-free
                    Consumer Hotline Is Available At 1-877-276-5550.
                  </p>
                  <p>
                    The Department Maintains A Recovery Fund To Make Payments Of
                    Certain Actual Out Of Pocket Damages Sustained By Borrowers
                    Caused By Acts Of Licensed Residential Mortgage Loan
                    Originators. A Written Application For Reimbursement From
                    The Recovery Fund Must Be Filed With And Investigated By The
                    Department Prior To The Payment Of A Claim. For More
                    Information About The Recovery Fund, Please Consult The
                    Department’s Website At Www.sml.texas.gov."
                  </p>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </section>

      <GoToTop />
    </div>
  )
}
