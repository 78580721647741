import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function LOTools() {
  let history = useHistory()
  const worksheetToolsButton = () => history.push('/worksheetTools')
  const guidelinesButton = () => history.push('/guidelines')
  const purchaseToolsButton = () => history.push('/purchaseTools')
  // const LOToolsVideosButton = () => history.push('/LOToolsVideos')

  return (
    <div className="LOToolsPage">
      <div>
      <h2>
        Loan Officer <span style={{ color: '#009ee5' }}>Tools</span>
      </h2>
      <h6>Didn't find what you are looking for?</h6>
      <Button
        className="Btn"
        variant="primary"
        href="https://us02web.zoom.us/j/9710308338?pwd=TTdHTVJ5Z2xDNTdxNUhjQWN5MnNLdz09"
        target="_blank"
      >
        Click Here for Support
      </Button>
      </div>
      <Button
        variant="primary"
        className="Btn"
        onClick={worksheetToolsButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Worksheets & Tools</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={guidelinesButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Guidelines, Forms & Cheat Sheets</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={purchaseToolsButton}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Purchase Tools</h3>
      </Button>
      {/* <Button
          variant="primary"
          className="Btn"
          onClick={LOToolsVideosButton}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Videos</h3>
        </Button> */}

      <GoToTop />
    </div>
  )
}
