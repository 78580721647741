import Button from 'react-bootstrap/Button'
import GoToTop from '../../Components/GoToTop'

export default function PurchaseTools() {
  return (
    <div className="purchaseToolsPage">
      <div>
        <h2>
          Purchase <span style={{ color: '#009ee5' }}>Tools</span>
        </h2>
      </div>
      <div className="tools">
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/purchaseTools%2FPRE-APPROVAL.pdf?alt=media&token=bd4c7b66-d323-4c44-88e6-9ae2aaa8c664"
          target="_blank"
        >
          <div>
            <img
              src="/assets/Screen Shot 2021-11-03 at 9.02.36 PM.png"
              alt="Screenshot of preapproval letter template"
              style={{ width: '60%' }}
            />
            <br />
            Pre Approval Letter
          </div>
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/purchaseTools%2FPre%20Approval.eml?alt=media&token=68459e60-c5f2-4e10-9501-0ca7d88de59c"
          target="_blank"
        >
          <div>
            <img
                src="/assets/preapprovalEmail.png"
                alt="screenshot of preapproval email template"
                style={{ width: '60%' }}
            />
            <br />
            Pre Approval Email
            </div>
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/purchaseTools%2FPURCHASE%20CONTACT%20SHEET%20.pdf?alt=media&token=77cbac39-5f62-4a15-99e4-3958d91f0086"
          target="_blank"
        >
          <img
            src="/assets/Screen Shot 2021-11-03 at 9.00.08 PM.png"
            alt="screenshot of hypotec contact sheet pdf"
            style={{ width: '60%' }}
          />
          <br />
          Hypotec Contact Sheet
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/purchaseTools%2FApproved%20Condo%20List.pdf?alt=media&token=f142dee0-2e1f-41e4-8e32-d0c794042ac6"
          target="_blank"
        >
          <img
            src="/assets/Screen Shot 2022-03-21 at 9.52.54 AM.png"
            alt="screenshot of approved condos"
            style={{ width: '60%' }}
          />
          <br />
          Approved Condo List
        </Button>
      </div>
      <GoToTop />
    </div>
  )
}
