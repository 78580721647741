import GoToTop from "../../Components/GoToTop"

export default function SalesVideo() {
  return (
    <div className="salesVideoPage">
      <h2>
        Sales <span style={{ color: '#009ee5' }}>Videos</span>
      </h2>
      <div className="videoContainer">
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%203.22.38%20PM.png?alt=media&token=4aceb0d2-f9f9-4c7e-b047-38ff5fc5ce6f"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-DiscloseFundingProcess.mp4?alt=media&token=c806b023-2713-4b95-a1ee-b61c60219d89"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%203.23.58%20PM.png?alt=media&token=a8aa2032-b49f-4de6-a476-d09302b18acb"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddyLoanOfficerProcess.mp4?alt=media&token=d41f658c-f1bf-44cb-8116-5b254f2f9e4f"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop/>
    </div>
  )
}
