import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function HumanResources() {
  let history = useHistory()
  const faqClick = () => history.push('/faq')
  const payrollSubmitClick = () => history.push('/payrollSubmit')
  const hrVideosClick = () => history.push('/hrVideos')
  const benefitsClick = () => history.push('/benefits')
  return (
    <>
      <div className="hrPage">
        <div>
          <h2>
            Human <span style={{ color: '#009ee5' }}>Resources</span>
          </h2>
          <h6>Didn't find what you are looking for?</h6>
          <Button
            className="Btn"
            variant="primary"
            href="https://us02web.zoom.us/j/7210814995?pwd=eVAxa094NXZCUFk1ajRLMXBKUGlYdz09"
            target="_blank"
          >
            Click Here for Support
          </Button>
        </div>
        <Button
          variant="primary"
          className="Btn"
          onClick={faqClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>FAQ</h3>
        </Button>
              {/*<Button
          variant="primary"
          className="Btn"
          onClick={payrollSubmitClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>How to Submit Payroll</h3>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={benefitsClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Benefits</h3>
        </Button>*/}
        <Button
          variant="primary"
          className="Btn"
          onClick={hrVideosClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Videos</h3>
        </Button>
      </div>
      <GoToTop />
    </>
  )
}
