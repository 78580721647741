import Button from 'react-bootstrap/Button'
import GoToTop from '../../Components/GoToTop'

export default function Articles() {
  return (
    <div className="articlesPage">
      <div>
        <h2>
          Marketing <span style={{ color: '#009ee5' }}>Articles</span>
        </h2>
      </div>
      <div className="articles">
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FHowtoInstagramStories.pdf?alt=media&token=d9a9b6fd-64a0-477d-8b4d-a45e123ca5a3"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/instagram.png"
            alt="screenshot of How to Instagram Stories pdf"
            style={{ width: '60%' }}
          />
          <br />
          How to Instagram Stories
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FLeverageSocial.pdf?alt=media&token=ca766dfe-1d4e-4a18-a199-5097f9e2eb33"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/leveragingSocial.png"
            alt="screenshot of How to Leverage Social Media pdf"
            style={{ width: '60%' }}
          />
          <br />
          How to Leverage Social Media
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FHowtoFacebookLive.pdf?alt=media&token=8a0eec9d-242f-48fa-a06b-73353f5838cd"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/facebook.png"
            alt="screenshot of How to Facebook Live pdf"
            style={{ width: '60%' }}
          />
          <br />
          How to Facebook Live
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FLinkedInNetworking.pdf?alt=media&token=d7a8457c-5a3f-4d83-880a-efc9e31d614a"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/LinkedInNetworking.png"
            alt="screenshot of LinkedIn Networking pdf"
            style={{ width: '60%' }}
          />
          <br />
          LinkedIn Networking
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FReasonsUseInstagramBusiness.pdf?alt=media&token=ec025bf6-b8e0-49da-845b-2bc456759752"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/ReasonsUseInstagram.png"
            alt="screenshot of 5 Reasons to Use Instagram for Business pdf"
            style={{ width: '60%' }}
          />
          <br />5 Reasons to Use Instagram for Business
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FReasonsUseSocialMedia.pdf?alt=media&token=a25d6201-3f1d-46fe-a7c4-4a524dcabb08"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/ReasonsUseSocialMediaBusiness.png"
            alt="screenshot of Reasons to Use Social Media pdf"
            style={{ width: '60%' }}
          />
          <br />
          Reasons to Use Social Media
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FStepsUsingCanva.pdf?alt=media&token=4690a00b-ac7a-4f91-8245-d6b0b63c8cb3"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/StepsUsingCanva.png"
            alt="screenshot of 3 Steps to using Canva pdf"
            style={{ width: '60%' }}
          />
          <br />3 Steps to using Canva
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FUsingBlankTemplate%20.pdf?alt=media&token=8eabe04c-cdb7-4fc1-ac04-37a1bec6e945"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/UsingBlankTemplate.png"
            alt="screenshot of Using a Blank Template pdf"
            style={{ width: '60%' }}
          />
          <br />
          Using a Blank Template
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FSocialMediaMarketingStrategyChecklist.pdf?alt=media&token=254d83d9-e5f5-4a0b-8aaf-18ee9cab4b95"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/MarketingStrategyChecklist.png"
            alt="screenshot of Social Media Marketing Strategy Checklist pdf"
            style={{ width: '60%' }}
          />
          <br />
          Social Media Marketing Strategy Checklist
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FSocialMediaCalendarPlanner.pdf?alt=media&token=56dd1756-15ec-4172-b5b4-5bb3b68db0e8"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/CalenderPlanner.png"
            alt="screenshot of Social Media Calender Planner pdf"
            style={{ width: '60%' }}
          />
          <br />
          Social Media Calender Planner
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FZillowProfile.pdf?alt=media&token=be6a2daf-1614-4f33-bfec-b7f8fa6edf2e"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/ZillowProfile.png"
            alt="screenshot of Steps to Create Zillow Profile pdf"
            style={{ width: '60%' }}
          />
          <br />
          Steps to Create Zillow Profile
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FIncreaseReferrals.pdf?alt=media&token=9f62f724-a6ce-4c20-97b2-c8c26b212720"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/IncreaseReferrals.png"
            alt="screenshot of Ways to Increase Referrals pdf"
            style={{ width: '60%' }}
          />
          <br />
          Ways to Increase Referrals
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2Fstaying%20compliant%20with%20marketing.pdf?alt=media&token=bbf9ab8b-3aa5-44dd-96f9-91141dc35f42"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/Screen Shot 2021-11-16 at 3.30.00 PM.png"
            alt="screenshot of Staying Compliant while Marketing pdf"
            style={{ width: '60%' }}
          />
          <br />
          Staying Compliant while Marketing
        </Button>
        <br />
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2Fsm%20best%20practices.pdf?alt=media&token=cb5eaed3-ceb5-442a-a2f1-97b4090a630a"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/Screen Shot 2021-11-16 at 3.33.05 PM.png"
            alt="screenshot of Social Media Best Practices pdf"
            style={{ width: '60%' }}
          />
          <br />
          Social Media Best Practices
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FCanva%20Keyboard%20shortcuts.pdf?alt=media&token=138dfed9-76ed-4acb-b308-84e5fae18a5e"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/Screen Shot 2022-01-06 at 10.30.31 AM.png"
            alt="screenshot of Canva Keyboard Shortcuts pdf"
            style={{ width: '60%' }}
          />
          <br />
          Canva Keyboard Shortcuts
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FLinkedin%20101%20Tips.pdf?alt=media&token=290a3d44-45d1-4a61-9347-42a3040f6065"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/Screen Shot 2022-03-18 at 3.22.50 PM.png"
            alt="linkedin 101 tips"
            style={{ width: '60%' }}
          />
          <br />
          Linkedin 101 Tips
        </Button>
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/articles%2FLinkedin%20Profille%20Template.pdf?alt=media&token=4cac0151-7663-4f74-b7d4-6f39eb0049b0"
          target="_blank"
          style={{ width: '25%' }}
        >
          <img
            src="/assets/Screen Shot 2022-03-18 at 3.27.08 PM.png"
            alt="linkedin profile template"
            style={{ width: '60%' }}
          />
          <br />
          Linkedin Profile Template
        </Button>
      </div>
      <GoToTop />
    </div>
  )
}
