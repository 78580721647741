import GoToTop from '../../Components/GoToTop'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'

export default function Compliance() {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const clickCompliance = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Compliance' })
    handleShow()
  }

  const initialState = {
    to: '',
    name: '',
    email: '',
    messageBody: '',
  }
  const [eachEntry, setEachEntry] = useState(initialState)
  let { name, email, messageBody, to } = eachEntry

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  const handleComplianceSubmit = (e) => {
    e.preventDefault()
    fetch('https://hypotec-lo-campus-api.web.app/complianceMessage', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.')
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form

          handleClose()
        } else if (response.status === 'fail') {
          alert('Message failed to send.')
        }
      })
  }
  return (
    <div className="compliancePage">
      <div>
        <h2 style={{ marginLeft: '5px' }}>
          Compliance <span style={{ color: '#009ee5' }}>Videos</span>
        </h2>
        <p style={{ marginLeft: '5px' }}>Have any compliance questions?</p>
        <Button className="Btn" onClick={clickCompliance}>
          Contact our Compliance Team
        </Button>
      </div>
      <div className="videoContainer">
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%202.20.41%20PM.png?alt=media&token=7b678261-a8c4-469e-b90a-4f6496bc8ee2"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FNewHireOrientation.mp4?alt=media&token=3d7d8e18-89d4-4a58-b738-8299af13b55f"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-31%20at%202.08.08%20PM.png?alt=media&token=f8548746-a280-49a5-9927-4fb422d880d9"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-AML.mp4?alt=media&token=bae8316f-374d-4c5f-b915-5ec83120f348"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-31%20at%202.08.23%20PM.png?alt=media&token=02e6ce9d-8219-41ff-9590-440392647bb5"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-GLB.mp4?alt=media&token=53eced46-febc-4db6-8a9d-508219e3a9ae"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-31%20at%202.09.00%20PM.png?alt=media&token=14bdad5f-33eb-4478-a5c1-ac2ba9d64a53"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-RedFlag.mp4?alt=media&token=2c43fdc3-99ff-448b-ab09-165354e135ab"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-31%20at%202.09.13%20PM.png?alt=media&token=45206925-ad4d-4148-a1c0-9c4dd78f38cd"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FBeverly-FairLendingTraining.mp4?alt=media&token=b1fe2477-d24d-492a-a6cb-c206b2a935ae"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="messageModal">
        <Modal.Header className="modal-title" closeButton closeLabel="">
          <Modal.Title>{`${to} question`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <p>Please note all questions will be answered within 72 hours.</p>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                onChange={handleOnChange}
                value={name}
              />
            </Form.Group>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                onChange={handleOnChange}
                value={email}
              />
            </Form.Group>
            <Form.Group
              style={{ padding: '10px' }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Compliance Question</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="messageBody"
                type="text"
                value={messageBody}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Button
              style={{ margin: '10px' }}
              variant="primary"
              type="submit"
              onClick={handleComplianceSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <GoToTop />
    </div>
  )
}
