import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function Sales() {
  let history = useHistory()
  const overcomingObjectionsPush = () => history.push('/overcomingObjections')
  const buildingBusinessClick = () => history.push('/buildingBusiness')
  const salesVideoPush = () => history.push('/salesVideo')
  const vendorsPush = () => history.push('/vendors')
  return (
    <div className="salesPage">
      <div style={{ flexDirection: 'column', textAlign: 'left' }}>
        <h2 style={{ textAlign: 'left' }}>
          Sales <span style={{ color: '#009ee5' }}>Resources</span>
        </h2>
        <h6>Didn't find what you are looking for?</h6>
        <Button
          className="Btn"
          variant="primary"
          href="https://us02web.zoom.us/j/9710308338?pwd=TTdHTVJ5Z2xDNTdxNUhjQWN5MnNLdz09"
          target="_blank"
        >
          Click Here for Support
        </Button>
      </div>
      <Button
        variant="primary"
        className="Btn"
        onClick={buildingBusinessClick}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Building My Business</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={overcomingObjectionsPush}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Scripts & Objection and Rebuttals</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={vendorsPush}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Vendors</h3>
      </Button>
      <Button
        variant="primary"
        className="Btn"
        onClick={salesVideoPush}
        style={{
          borderRadius: '50%',
          width: '225px',
          height: '225px',
          border: '1px #0177b5',
          flexDirection: 'row',
        }}
      >
        <h3>Videos</h3>
      </Button>
      <GoToTop/>
    </div>
  )
}
