import GoToTop from '../../Components/GoToTop'

export default function DSCRLoan() {
  return (
    <div className="dscrLoanPage">
      <h2>
        Debt Service Coverage Ratio
        <span style={{ color: '#009ee5' }}> Loan</span>
      </h2>
      <div>
        <br />
        <li style={{ fontWeight: 'bold' }}>FOLLOW FANNIE MAE DU FINDINGS</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>NO INCOME OR TAX RETURNS NEEDED</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          LOAN QUALIFIES BASED ON DEBT SERVICE (PITI vs. RENT COMPS or RENTAL
          AGREEMENT)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          MINIMUM SCORE 620 (BEST SUITED FOR 680 +)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          PURCHASE LTV - UP TO 80% (760 + FICO NEEDED FOR 90% LTV)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 80% (760 + FICO NEEDED FOR 90% LTV)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>CASH OUT REFINANCE LTV – 80% MAX</li>
      </div>
      <GoToTop />
    </div>
  )
}
