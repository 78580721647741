import GoToTop from "../../Components/GoToTop"


export default function CRM() {
  return (
    <div className="CRMPage">
      <h2>
        CRM <span style={{ color: '#009ee5' }}>Resource</span>
      </h2>
      <div className="videoContainer">
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-%20TechTrainingVelocify.png?alt=media&token=56435b41-379f-4e9f-b480-6c698767c3d6"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-%20TechTrainingVelocify.mp4?alt=media&token=6a9bb910-02b0-46a0-a1eb-8315105af964"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop/>
    </div>
  )
}
