import GoToTop from '../../Components/GoToTop'

export default function TopInvestors() {
  return (
    <div className="topInvestorsPage">
      <div className="topInvestorHeaders">
        <h2>
          Top Investors per <span style={{ color: '#009ee5' }}>Product</span>
        </h2>
        <h6>
          These are choices are based on highest level of service, fastest speed
          and competitive pricing.
        </h6>
      </div>
      <div>
        <h4>Traditional Mortgages</h4>
        <h6>CONVENTIONAL (FANNIE / FREDDIE):</h6>
        <ol>1. UWM</ol>
        <ol>2. HOMEBRIDGE</ol>
        <ol>3. FREEDOM MORTGAGE</ol>
        <br />
        <h6>FHA:</h6>
        <ol>1. UWM</ol>
        <ol>2. FREEDOM MORTGAGE</ol>
        <ol>3. HOMEBRIDGE</ol>
        <br />
        <h6>VA:</h6>
        <ol>1. UWM</ol>
        <ol>2. FREEDOM MORTGAGE </ol>
        <ol>3. VILLAGE CAPITAL (NO FICO VA IRRRL)</ol>
        <br />
        <h6>USDA:</h6>
        <ol>1. UWM</ol>
        <br />
        <h6>JUMBO:</h6>
        <ol>1. UWM</ol>
        <ol>2. HOMEBRIDGE</ol>
        <ol>3. FREEDOM MORTGAGE</ol>
        <br />
        <h6>REVERSE MORTGAGE:</h6>
        <ol>1. FINANCE OF AMERICA</ol>
      </div>
      <div>
        <h4>NonTraditional Mortgages</h4>
        <h6>BANK STATEMENT / 1099:</h6>
        <ol>1. HOMEBRIDGE</ol>
        <ol>2. REMN</ol>
        <ol>3. ANGEL OAK</ol>
        <br />
        <h6>DEBT SERVICE COVERAGE RATIO LOAN:</h6>
        <ol>1. HOMEBRIDGE</ol>
        <ol>2. REMN</ol>
        <ol>3. ANGEL OAK</ol>
        <br />
        <h6>40 YEAR INTEREST ONLY:</h6>
        <ol>1. HOMEBRIDGE</ol>
        <ol>2. REMN</ol>
        <ol>3. ANGEL OAK</ol>
        <ol>4. OAKTREE</ol>
        <br />
        <h6>FOREIGN NATIONALS</h6>
        <ol>1. BRADESCO BAC FLORIDA (FL, TX and NY)</ol>
        <ol>2. HOMEBRIDGE (Qualifies on DSCR)</ol>
        <br />
        <h6>BRIDGE LOANS:</h6>
        <ol>1. CONTACT TEAM LEAD</ol>
        <br />
        <h6>COMMERCIAL LOANS:</h6>
        <ol>1. CONTACT TEAM LEAD</ol>
        <br />
        <h6>NO INCOME NO EMPLOYMENT LOAN:</h6>
        <ol>1. CHANGE WHOLESALE</ol>
      </div>
      <GoToTop />
    </div>
  )
}
