import GoToTop from '../../Components/GoToTop'
import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { useState, useEffect } from 'react'

export default function LoanOfficerBenefits() {
  const initialState = {
    funded: 0,
    currentComp: 0,
    newFunded: 0,
    hypotecComp: 2,
    companyFee: 15,
    netLOComp: 1.7,
    LOComp: 0,
    hypotecLOComp: 0,
    monthlyIncrease: 0,
    yearlyIncrease: 0,
  }
  const [calcChange, setCalcChange] = useState(initialState)
  const [calculatedFields, setCalculatedFields] = useState(initialState)
  // let {funded, currentComp, newFunded, x} = calcChange

  useEffect(() => {
    let LOComp =
      (Number(calcChange.funded) * Number(calcChange.currentComp)) / 10000
    let hypotecLOComp =
      (Number(calcChange.newFunded) * Number(calcChange.netLOComp)) / 100
    let monthlyIncrease = hypotecLOComp - LOComp
    let yearlyIncrease = monthlyIncrease * 12
    setCalculatedFields({
      ...calcChange,
      yearlyIncrease: yearlyIncrease,
      monthlyIncrease: monthlyIncrease,
      hypotecLOComp: hypotecLOComp,
      LOComp: LOComp,
    })
  }, [calcChange]) // pass `value` as a dependency

  const handleCalcChange = (e) => {
    let numberValue = e.target.value.replace(/\D/g,'')
    setCalcChange({ ...calcChange, [e.target.name]: numberValue })
  }

  return (
    <>
      <div className="LObenefitsPage">
        <div className="benefitsImgs" style={{ width: '50%', padding: '50px' }}>
          <Image
            src="/assets/Untitled.png"
            alt="laptop with a map"
            style={{ width: '50%', padding: '0 30px 30px 0' }}
          />
        </div>
        <div className="benefits" style={{ width: '50%', padding: '75px' }}>
          <h3 style={{ fontWeight: 'bold' }}>
            Anytime, Anywhere
            <span style={{ color: '#009ee5' }}>Flexibility</span>
          </h3>
          <p>
            Loan Officers are inherently mobile. Hypotec’s cloud-campus
            environment allows for collaboration and shared learning from any
            location in the world.
          </p>
          <p>
            In addition to our favorable flexible work environment, loan
            officers are provided more than 40 hours of live training. We have
            developed systems in which our team can participate in virtual
            classrooms for continued professional enhancement led by leading
            expert loan officers.
          </p>
        </div>
        <div className="blueSection">
          <div className="benefits" style={{ width: '50%', padding: '85px' }}>
            <h3 style={{ fontWeight: 'bold', padding: '30px 0 0 0' }}>
              Powerful Suite of Tools
            </h3>
            <p>
              We’re here to set you up for success. Hypotec provides all loan
              officers with industry leading tools and services to grow their
              business. This includes top-tier lead-generation software, lead
              management, content marketing tools as well as an abundance of
              advertising support.
            </p>
          </div>
          <div
            className="benefitsImgs"
            style={{ width: '50%', padding: '50px' }}
          >
            <Image
              src="/assets/Untitled2.png"
              alt="tablet with a handing pointing at it"
              style={{ width: '50%', padding: '0 0 30px 30px' }}
            />
          </div>
        </div>
        <div className="benefitsImgs" style={{ width: '50%', padding: '50px' }}>
          <Image
            src="/assets/Untitled1.png"
            alt="3 bars of growing sizes and an arrow going up, with a hand and pen"
            style={{ width: '50%', padding: '0 30px 0 0' }}
          />
        </div>
        <div className="benefits" style={{ width: '50%', padding: '65px' }}>
          <h3 style={{ fontWeight: 'bold' }}>
            Loan Officer’s Financial
            <span style={{ color: '#009ee5' }}> Rewards</span>
          </h3>
          <p>
            No hidden fees, no loan transaction fees, no monthly fees. As part
            of the Hypotec team, loan officers earn up to 80% of their
            commissions and participate in one of the industry’s most rewarding
            loan officer experiences.
          </p>
          <p>
            Not only is our team able to benefit from one of the highest paid
            compensation structures, our loan offices also have the ability to
            receive revenue share from the sales activity of the productive loan
            officers they recruit into the company. Revenue share is paid by
            Hypotec and does not reduce our loan officer’s commission.
          </p>
        </div>
      </div>
      <div className="compHeaders">
        <h2>EARN MORE WITH HYPOTEC</h2>
      </div>
      <div className="blueSectionComp">
        <br />
        <div className="oldSection">
          <h4>OUT WITH THE OLD</h4>
          <br />
          <label htmlFor="funded">TOTAL VOLUME FUNDED </label>
          <InputGroup size="md" style={{ width: '55%' }}>
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              type="text"
              name="funded"
              id="funded"
              value={Number(calcChange.funded).toLocaleString()}
              onChange={handleCalcChange}
            />
          </InputGroup>
          <br />
          <label htmlFor="currentComp">
            YOUR CURRENT COMPENSATION (IN BPS)
          </label>
          <InputGroup size="md" style={{ width: '55%' }}>
            <FormControl
              type="text"
              name="currentComp"
              onChange={handleCalcChange}
            />
          </InputGroup>
          <br />
          <label htmlFor="LOComp">LO COMPENSATION</label>
          <InputGroup.Text
            size="md"
            style={{ width: '55%', backgroundColor: 'white' }}
          >
            $ {calculatedFields.LOComp.toLocaleString()}
          </InputGroup.Text>
        </div>
        <br />
        <h3>VS</h3>
        <br />
        <div className="newSection">
          <h4>IN WITH THE NEW </h4>
          <br />
          <label htmlFor="newFunded">TOTAL VOLUME FUNDED</label>
          <InputGroup size="md" style={{ width: '60%' }}>
            <InputGroup.Text>$</InputGroup.Text>
            <FormControl
              type="text"
              name="newFunded"
              id="newFunded"
              value={Number(calcChange.newFunded).toLocaleString()}
              onChange={handleCalcChange}
            />
          </InputGroup>
          <br />
          <h6>COMPENSATION {calcChange.hypotecComp}%</h6>
          <h6>COMPANY FEE {calcChange.companyFee}%</h6>
          <h6>NET LO COMP {calcChange.netLOComp}0%</h6>
          <label htmlFor="newLOCOMP">HYPOTEC LO COMPENSATION</label>
          <InputGroup.Text
            size="md"
            style={{ width: '60%', backgroundColor: 'white' }}
          >
            $ {calculatedFields.hypotecLOComp.toLocaleString()}
          </InputGroup.Text>
        </div>
        <div className="increaseSection">
          <h4>MONTHLY PAY INCREASE: ${calculatedFields.monthlyIncrease}</h4>
          <h4>YEARLY PAY INCREASE: ${calculatedFields.yearlyIncrease}</h4>
        </div>
      </div>
      <GoToTop />
    </>
  )
}
