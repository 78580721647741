import GoToTop from '../../Components/GoToTop';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useHistory } from 'react-router';

export default function LinkedInGallery() {
  const history = useHistory();
  const linkedinPostsClick = () => history.push('/linkedinPosts');
  const linkedinBannerClick = () => history.push('/linkedinBanners');

  return (
    <div className="linkedInGalleryPage">
      <div className="linkedInHeaders">
        <h2>
          LinkedIn <span style={{ color: '#009ee5' }}>Gallery</span>
        </h2>
      </div>
      <Card style={{ padding: '10px', width: 'min-content' }}>
        <h5>LinkedIn Posts</h5>
        <Card.Body>
          <Button className="Btn" onClick={linkedinPostsClick}>
            <img
              src="/assets/Screen Shot 2022-03-21 at 11.02.23 AM.png"
              alt="empty hypotec post"
              style={{ margin: '10px', width: '200px' }}
            />
            See more
          </Button>
        </Card.Body>
      </Card>
      <Card style={{ padding: '10px', width: '310px' }}>
        <h5>LinkedIn Banner Photos</h5>
        <Card.Body>
          <Button className="Btn" onClick={linkedinBannerClick}>
            <img
              src="/assets/Screen Shot 2022-03-21 at 11.01.44 AM.png"
              alt="hypotec logo"
              style={{ margin: '10px', width: '200px' }}
            />
            <br />
            See More
          </Button>
        </Card.Body>
      </Card>
      <GoToTop />
    </div>
  );
}
