import GoToTop from "../../Components/GoToTop"

export default function LoanOriginator() {
  return (
    <div className="loanOriginatorPage">
      <h2>
        Loan Originator <span style={{ color: '#009ee5' }}>Software</span>
      </h2>
      <div className="videoContainer">
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-RegzLE.png?alt=media&token=f3d57958-5956-4830-a664-a9c6ddb078ea"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-RegzLE.mp4?alt=media&token=8356cfda-961c-4f55-a6f7-5f31109d2d98"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-BorrowSummary.png?alt=media&token=6b7224df-3595-4452-8ac9-cbd0497865e0"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-BorrowerSummary.mp4?alt=media&token=f7efaf8d-0f11-42aa-93e2-27de51064fcc"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-1003Part1.png?alt=media&token=c6a0b104-7232-49f5-b22a-6753ebe721b0"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-1003Part1.mp4?alt=media&token=12f91f6f-aec2-43be-a880-0b406b664bbc"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-1003Part2.png?alt=media&token=e08aaefd-0f28-4c3e-9373-070144255e97"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-1003Part2.mp4?alt=media&token=6fded317-e9c7-4e9f-ab26-fffda7cd5f4e"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-1003Part3.png?alt=media&token=41b101b3-49be-49ea-b569-aadb8dbc231a"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-1003Part3.mp4?alt=media&token=40881be5-fd2b-4283-92ed-fcadd1c8a9b3"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-1003Part4.png?alt=media&token=f24d67a2-96db-4198-a068-2c5ec33bf4e8"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-1003Part4.mp4?alt=media&token=6b47521b-6b25-4da6-8949-504fb78d68d6"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-URLALender.png?alt=media&token=62738605-844a-4416-97a0-c78737e28ba9"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-URLALender.mp4?alt=media&token=5ca55449-b583-4a2b-a946-e0a2988df0f9"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FFreddy-2015Itemization.png?alt=media&token=9a9e720a-662e-4e68-9e56-8644c2506c74"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddy-2015Itemization.mp4?alt=media&token=cc41161f-0ff3-47c9-a434-76eb3fbd4496"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%202.11.04%20PM.png?alt=media&token=f16a21dd-a079-443d-aa65-66ea09c9ac8c"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddyEmcompassDU%26LP.mp4?alt=media&token=9a8b8b0f-23f0-4aa1-9940-6ff245ff3a0f"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="videos">
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-30%20at%202.11.30%20PM.png?alt=media&token=0021d6df-34d4-4522-b976-3c438a04bde4"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FFreddyMortgageInsurance.mp4?alt=media&token=523b6d8f-771b-423a-ab1f-8937939dae0c"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop/>
    </div>
  )
}
