import { useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import firebase from 'firebase'
import 'firebase/auth'
import firebaseConfig from '../../config'
import { UserAuthContext } from '../../App'
import GoToTop from '../../Components/GoToTop'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default function Home() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setUser } = useContext(UserAuthContext)
  let history = useHistory()

  const handleLogin = (e) => {
    e.preventDefault()
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        const user = response.user
        const email = user.email
        fetch(`https://hypotec-lo-campus-api.web.app/users/${email}`)
          .then((res) => res.json())
          .then((userObjectFromFireStore) => {
            const userInLocalStorage = JSON.stringify(userObjectFromFireStore)
            localStorage.setItem('user', userInLocalStorage)
            setUser(userObjectFromFireStore)
            if (!userObjectFromFireStore.complianceVideosWatched) {
              history.push('/required')
            } else {
              history.push('/employee')
            }
          })
      })
      .catch((error) =>
        alert('Error while logging in. Please try again', error)
      )
  }

  function resetPassword() {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((res) => {
        alert('email sent')
      })
      .catch((error) => {
        alert('error sending password reset' + error)
      })
  }

  return (
    <div className="homePage">
      <div>
        {/* <Image src="/dotsleft.png" alt="dots" className="leftDots d-none-xs" />
        <Image
          src="/dotsright.png"
          alt="dots2"
          className="rightDots d-none-xs"
        /> */}
        <Container
          fluid
          className="loginContainer"
          style={{
            width: '45%',
            flexDirection: 'row',
            borderRadius: '15px',
            display: 'flex',
          }}
        >
          <Form style={{ width: '100%' }} onSubmit={(e) => handleLogin(e)}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="Btn"
              style={{ width: '99%' }}
            >
              Sign In
            </Button>
            <br />
            <Button
              variant="link"
              style={{ marginTop: '10px', color: 'gray', marginLeft: '-10px' }}
              onClick={() => resetPassword()}
            >
              Forgot Password?
            </Button>
            <Link to="/signup" style={{ float: 'right', margin: '15px' }}>
              Sign Up
            </Link>
          </Form>
        </Container>
      </div>
      <div className="headers">
        <h3>Not a team member yet? </h3>
        <h4>
          Check out why people are choosing Hypotec to take their career to the
          next level.
        </h4>
      </div>
      <div style={{ textAlign: 'center' }}>
        <video
          controls
          poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FHypotecVideo.png?alt=media&token=20b09208-556c-4819-bd98-0160b0467de9"
          width="600"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FWhy%20Hypotec%20-%20Loan%20Officer%20Recruitment%20-%20Final.mp4?alt=media&token=ef48cc5e-1862-45e8-9419-3674acdf85ce"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="hrForm">
        <h5 style={{ textAlign: 'center' }}>
          Interested in learning more about Hypotec’s career opportunities?
        </h5>
        <h5 style={{ padding: '25px', textAlign: 'center' }}>
          Schedule an introductory call with our HR team today!
        </h5>
        <div style={{ textAlign: 'center' }}>
          <Button
            href="https://calendly.com/hypotecmortgages/15min"
            className="Btn"
            target="_blank"
          >
            Schedule a Call
          </Button>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
