import GoToTop from "../../Components/GoToTop"

export default function ForeignNationals() {
  return (
    <div className="foreignNationalsPage">
      <h2>
        Foreign <span style={{ color: '#009ee5' }}>Nationals</span>
      </h2>
      <div>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          NO CREDIT NEEDED (EXCEPT IF CLIENT IS FROM CANADA OR THE UK)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          INCOME IS BASED ON AN ACCOUNTANT LETTER
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          POST CLOSING RESERVES NEEDED (MINIMUM 12 MONTHS)
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          PURCHASE LTV - UP TO 75%
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 75%
        </li>
        <br />
        {/* <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 90% (760 + FICO NEEDED FOR 90% LTV) FOR
          CONDOS
        </li> */}
        <br />
        <li style={{ fontWeight: 'bold' }}>CASH OUT REFINANCE LTV – 75% MAX</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>CLOSING UNDER LLC OR CORP IS OK</li>
      </div>
      <GoToTop/>
    </div>
  )
}
