import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'

export default function FacebookGallery() {
  const history = useHistory()
  const fbPostsClick = () => history.push('/fbPosts')
  const coverClick = () => history.push('/coverPhotos')

  return (
    <div className="facebookGalleryPage">
      <div>
        <h2>
          Facebook <span style={{ color: '#009ee5' }}>Gallery</span>
        </h2>
      </div>

      <Card style={{ padding: '10px', width: 'min-content' }}>
        <h5>Facebook Posts</h5>
        <Card.Body>
          <Button className="Btn" onClick={fbPostsClick}>
            <img
              src="/assets/FB1edit.jpeg"
              alt="alarm clock with saying 'DON'T SLEEP ON THESE RATES CONTACT ME TODAY!' above it"
              style={{ margin: '10px', width: '200px' }}
            />
            See more
          </Button>
        </Card.Body>
      </Card>
      <Card style={{ padding: '10px' }}>
        <h5>Facebook Cover Photos</h5>
        <Card.Body>
          <Button className="Btn" onClick={coverClick}>
            <img
              src="/assets/fbCover2edit.jpg"
              alt="Hypotec logo with 'If you're ready to take you career to the next level, send your resume over today and we'll be in contact with qualified applications shortly' underneath it"
              style={{ margin: '10px', width: '200px' }}
            />
            <br />
            See More
          </Button>
        </Card.Body>
      </Card>
      <GoToTop />
    </div>
  )
}
