import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import GoToTop from '../../Components/GoToTop'

export default function FAQ() {
  return (
    <div className="FAQPage">
      <h2>
        Frequently Asked <span style={{ color: '#009ee5' }}>Questions</span>
      </h2>
      <div className="faqContainer">
        <Container fluid>
                  {/* }<Row>
            <Col>
              <li style={{ textAlign: 'left', fontWeight: 'bold' }}>
                How do I virtually sign up?
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              Hypotec operates with Amazon Workspace! Amazon WorkSpace is a
              cloud-based virtual desktop that can act as a replacement for a
              traditional desktop; it comes fully equipped with compute
              resources, storage space, and software applications that allow a
              user to perform day-to-day tasks just like using a traditional
              desktop!
            </Col>
          </Row>
          <br />*/}
          <Row>
            <Col>
              <li style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Do you prefer that I have a MAC or Windows operating system?
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              Either or works for us! We prefer you to use whatever your most
              comfortable with to successfully complete your job!
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <li style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Does Hypotec provide the needed technology to start my new
                career?
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              We do not, we do require that you have the following equipment for
              a successful start: Computer with (2) Screens, Mouse, Keyboard,
              USB Headset, and Internet Connection.
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <li style={{ textAlign: 'left', fontWeight: 'bold' }}>
                What will my first day look like?
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              You will receive a ‘First Day Itinerary’ via email from our Human
              Resources Department, that provides details on where to
              ‘virtually’ report to on your first day. You will receive a New
              Hire Orientation that goes over our entire employee handbook and
              company expectations, to begin!
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <li style={{ textAlign: 'left', fontWeight: 'bold' }}>
                Where can I find the employee handbook or payroll schedule?
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              Once you have initiated the onboarding paperwork process via
              Paychex Flex, you will see a few documents available for you to
              download under the ‘documents to download’ database. If you do not
              see it, you can always reach out to our HR department to have a
              copy sent!
            </Col>
          </Row>
        </Container>
      </div>
      <GoToTop />
    </div>
  )
}
