import GoToTop from '../../Components/GoToTop'

export default function HighlightCovers() {
  return (
    <div className="highlightCoversPage">
      <h2>
        Instagram Highlight Cover
        <span style={{ color: '#009ee5' }}> Images</span>
      </h2>
      <div className="gallery">
        <div>
          <button className="container">
            <a href="/assets/IGimage1.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramHighlight%2FIG%20Highlight%20Cover%20image%20family.jpg?alt=media&token=b94e8103-5746-4d66-847d-64d03985d47d"
                alt="gray background with blue circle- inside is a home with family of 4 and a heart inside"
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGimage2.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramHighlight%2FIGImage2.jpg?alt=media&token=edf16afc-8826-4bcc-813c-9183eae0f9db"
                alt="blue background with gray circle- talking bubble says 'DID YOU KNOW?' inside"
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGimage3.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramHighlight%2FIGimage3.jpg?alt=media&token=77eb4e31-6d92-4171-915e-c09da7243d14"
                alt="blue background with white circle with an H inside"
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
        <div>
          <button className="container">
            <a href="/assets/IGimage4.jpg" download>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/instagramHighlight%2FIGimage4.jpg?alt=media&token=8c19c2cb-5ffb-4d03-a266-d39db9763322"
                alt="gray background with blue circle have a newspaper that says 'NEWS' inside"
                className="image"
                style={{ width: '200px' }}
              />
              <div className="middle">
                <div className="text">Download</div>
              </div>
            </a>
          </button>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
