import GoToTop from '../../Components/GoToTop'

export default function ToolsVideos() {
  return (
    <div className="toolsVideosPage">
      <h2>
        Loan Officer Tools <span style={{ color: '#009ee5' }}>Videos</span>
      </h2>
      <GoToTop />
    </div>
  )
}
