import GoToTop from '../../Components/GoToTop'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Container from 'react-bootstrap/Container'

export default function PrivacyPolicy() {
  return (
    <div className="privacyPolicyPage">
      <Container className="privacyContainer">
        <div className="row">
          <div className="col-md-12">
            <div className="all-caption termofuseText">
              <h1 style={{ fontWeight: 'bold', fontSize: '3rem' }}>
                Privacy <span style={{ color: '#009ee5' }}>Policy</span>
              </h1>
              <p>
                Client privacy is something we take very seriously. We
                acknowledge and empathize that your privacy is important. This
                Privacy Policy describes how we collect and use the personal
                information that we collect from you through our website,
                application, phone, email or text.
              </p>
            </div>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row style={{ paddingTop: '50px' }}>
              <Col sm={3}>
                <Nav className="maintbs flex-column nav-pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Our pledge to you</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      Collecting Information
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Your Privacy</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">Opt out</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <p>
                      At Hypotec, we have dedicated ourselves to pioneering the
                      home loan process and helping individuals use their home
                      financing options as a tool to empower their financial
                      lives. With that effort, we respect and protect the
                      privacy of those who visit or use our various channels of
                      application.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <p>
                      When we collect information from you, we want you to know
                      how it is used. By utilizing our various communication
                      channels, you are consenting to the use of information and
                      agreeing to these guidelines alongside all other Hypotec
                      policies depicted on our website.
                    </p>
                    <p>
                      {' '}
                      When you use the website or any other communication
                      channel, we may collect personal information about you,
                      such as your name, address, Social Security number,
                      telephone number, email address, asset information, income
                      information, or any other information that you submit on
                      applications or other forms made available through the
                      website, phone, email or text.
                    </p>
                    <p>
                      We may also collect personal information about you from
                      third party sources. This may be combined with information
                      that you provided to us or we previously collected. Under
                      no circumstances do we sell your data.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <p>
                      Hypotec does not share your personal information with
                      outside companies for their promotional use without your
                      consent. When working with our team, we will verify your
                      identity using your personal info to help protect against
                      fraud. We use encryption technologies when transmitting
                      information to and from the website and application.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <p>
                      You may opt-out of any future marketing contacts from us
                      at any time. You can do the following by visiting{' '}
                      <a
                        href="http://stage.hypotec.com/Aboutus/CommunicationOptOut"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://stage.hypotec.com/Aboutus/CommunicationOptOut
                      </a>
                    </p>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>

      <GoToTop />
    </div>
  )
}
