import GoToTop from "../../Components/GoToTop"

export default function NoIncomeLoan() {
  return (
    <div className="noIncomeLoanPage">
      <h2>
        No Income No Employment <span style={{ color: '#009ee5' }}>Loan</span>
      </h2>
      <div>
        <br />
        <li style={{ fontWeight: 'bold' }}>OWNER OCCUPIED ONLY</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>NO EMPLOYMENT OR INCOME ON 1003</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>MINIMUM CREDIT SCORE 640</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>PURCHASE LTV - UP TO 80%</li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          RATE AND TERM REFINANCE LTV – UP TO 80%
        </li>
        <br />
        <li style={{ fontWeight: 'bold' }}>
          CASH OUT REFINANCE LTV – 75% MAX CONDOS
        </li>
        <li style={{ fontWeight: 'bold' }}>
        POST CLOSING RESERVES 12 MONTHS MINIMUM
        </li>
      </div>
      <GoToTop/>
    </div>
  )
}
