import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import { UserAuthContext } from '../../App'
import GoToTop from '../../Components/GoToTop'

export default function Employee() {
  const history = useHistory()
  const handleClick = () => history.push('/resource')
  const complianceClick = () => history.push('/compliance')
  const { user } = useContext(UserAuthContext)

  return (
    <div className="employeePage">
      <div className="welcomeHeaders">
        <h1>
          Welcome{' '}
          <span style={{ color: '#009ee5' }}>
            {user ? user.firstName : 'Guest'}!
          </span>
        </h1>
        <h6>
          Being a part of the Hypotec family allows you access to a variety of
          exclusive, top notch resources. Looking for guidance or support while
          building your business? Check out all of our resources available to
          you now that you’re part of our industry leading team. From live Zoom
          connections, to articles, to videos – everything you need to help you
          succeed is available at the click of a button.
        </h6>
      </div>
      <span className="welcomeButtons">
        <Button
          variant="primary"
          className="Btn"
          href="https://us02web.zoom.us/j/7210814995?pwd=eVAxa094NXZCUFk1ajRLMXBKUGlYdz09"
          target="_blank"
          style={{
            borderRadius: '50%',
            width: '275px',
            height: '275px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '80px',
          }}
        >
          <h4>Corporate Support</h4>
          <h6>
            Have general inquiries about your employment with Hypotec?Connect
            with our live support team Monday – Friday, <br /> 9AM EST – 6PM EST.
          </h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          href="https://us02web.zoom.us/j/9710308338?pwd=TTdHTVJ5Z2xDNTdxNUhjQWN5MnNLdz09"
          target="_blank"
          style={{
            borderRadius: '50%',
            width: '275px',
            height: '275px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '80px',
          }}
        >
          <h4>Loan Officer Support</h4>
          <h6>
            Our Operations team is on hand to help with any support or deal
            related questions that come up.
          </h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={handleClick}
          style={{
            borderRadius: '50%',
            width: '275px',
            height: '275px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h4>Resource Center</h4>
          <h6>
            A full on digital platform designed to give you all of the tutorials
            and resources you need to succeed in the industry.
          </h6>
        </Button>

        <Button
          variant="primary"
          className="Btn"
          href="https://us02web.zoom.us/j/5790850122"
          target="_blank"
          style={{
            borderRadius: '50%',
            width: '275px',
            height: '275px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '85px',
          }}
        >
          <h4>Education</h4>
          <h6>
            Every first and third Tuesday of the month, join our expert team as
            we discuss tips and tricks to succeed in your role.
          </h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          href="https://us02web.zoom.us/j/7426030691?pwd=TXJtYjZVeElWdDV2ekY0c253UVJIdz09"
          target="_blank"
          style={{
            borderRadius: '50%',
            width: '275px',
            height: '275px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '85px',
          }}
        >
          <h4>Hypotec Empower</h4>
          <h6>
            For our Empower team, exclusive access to extended resources Monday
            – Friday, 9AM EST – 6PM EST.
          </h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={complianceClick}
          style={{
            borderRadius: '50%',
            width: '275px',
            height: '275px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '25px'
          }}
        >
          <h4>Compliance</h4>
          <h6>
            Questions regarding a compliance issue? Access all training videos
            as needed to ensure you stay on top of all industry compliance
            factors.
          </h6>
        </Button>
      </span>
      <GoToTop/>
    </div>
  )
}
