import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { UserAuthContext } from '../../App'
import firebaseConfig from '../../config'
import firebase from 'firebase'
import 'firebase/auth'
import GoToTop from '../../Components/GoToTop'

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { setUser } = useContext(UserAuthContext)

  const allowList = ['hypotec.com']

  let history = useHistory()

  function validateEmail() {
    const splitArray = email.split('@')
    if (allowList.indexOf(splitArray[1]) >= 0) {
      return true
    }
    return false
  }

  function createUser(uid) {
    const user = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      uid,
      complianceVideosWatched: 0
    }
    fetch('https://hypotec-lo-campus-api.web.app/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(user),
    })
      .then((res) => res.json())
      .then(() => {
        setUser(user)
        history.push('/required')
      })
      .catch((err) => alert(err))
  }
  const handleSignup = (e) => {
    e.preventDefault()
    if (!validateEmail()) {
      alert('must use a Hypotec email')
      return
    }
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        const json = JSON.stringify(res.user)
        localStorage.setItem('user', json)
        createUser(res.user.uid)
        console.log(res)
      })
      .catch((error) => alert(error))
  }
  return (
    <>
     
      <Container className="signUpContainer">
        <Form onSubmit={handleSignup}>
          <Form.Group className="mb-3" controlId="formBasicFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="firstName"
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="lastName"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="@hypotec.com"
              alert="must enter @hypotec.com email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button className="Btn" variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
      <GoToTop />
    </>
  )
}
