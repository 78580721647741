import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router'
import GoToTop from '../../Components/GoToTop'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'

export default function Marketing() {
  const history = useHistory()
  const clickButton = () => history.push('/articles')
  /* const pushButton = () => history.push('/digital') */
    const marketingTemplates = () => history.push('/marketingTemplates')
  const clickCircle = () => history.push('/print')
  const circleButton = () => history.push('/marketingVideos')
  const pushCircle = () => history.push('/store')
  const complianceClick = () => history.push('/marketingCompliance')

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const clickMarketing = (e) => {
    e.preventDefault()
    setEachEntry({ ...eachEntry, to: 'Marketing' })
    handleShow()
  }
  const initialState = {
    to: '',
    name: '',
    email: '',
    messageBody: '',
  }
  const [eachEntry, setEachEntry] = useState(initialState)
  let { name, email, messageBody, to } = eachEntry

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  const handleMarketingSubmit = (e) => {
    e.preventDefault()
    fetch('http://hypotec-lo-campus-api.web.app/complianceMessage', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.')
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form
          console.log('message sending')
          handleClose()
        } else if (response.status === 'fail') {
          alert('Message failed to send.')
        }
      })
  }
  return (
    <div className="marketingPage">
      <div className="marketingHeaders">
        <h2>
          Marketing <span style={{ color: '#0293D7' }}>Resources</span>
        </h2>
        <h6>Everything you need to market yourself and earn more.</h6>
        <p style={{ marginLeft: '5px' }}>Have any Marketing questions?</p>
        <Button className="Btn" onClick={clickMarketing}>
          Contact our Marketing Team
        </Button>
      </div>
      <div className="marketingBtns">
        <Button
          variant="primary"
          className="Btn"
          onClick= {clickButton}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Articles</h3>
          <h6>
            Need help getting started? Check out our archive of articles to
            accelerate your business.
          </h6>
        </Button>
              {/* }<Button
          variant="primary"
          className="Btn"
          onClick={pushButton}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Digital Design Templates</h3>
          <h6>Your one stop shop for all digital graphics.</h6>
        </Button>*/}
              <Button
                  variant="primary"
                  className="Btn"
                  onClick={marketingTemplates}
                  style={{
                      borderRadius: '50%',
                      width: '225px',
                      height: '225px',
                      border: '1px #0177b5',
                      flexDirection: 'row',
                  }}
              >
                  <h2>Marketing Templates</h2>
              </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={clickCircle}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '20px',
          }}
        >
          <h3>Print Design Templates</h3>
          <h6>
            Here you’ll find flyers, direct mail, post cards <br />
            and more.
          </h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={pushCircle}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '25px',
          }}
        >
          <h3>Hypotec Store</h3>
          <h6>
            Find all merchandise needs for yourself and your clients in our
            storefront.
          </h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={complianceClick}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
            paddingTop: '25px',
          }}
        >
          <h3>Marketing Compliance</h3>
          <h6>All you need to remain compliant when marketing.</h6>
        </Button>
        <Button
          variant="primary"
          className="Btn"
          onClick={circleButton}
          style={{
            borderRadius: '50%',
            width: '225px',
            height: '225px',
            border: '1px #0177b5',
            flexDirection: 'row',
          }}
        >
          <h3>Videos</h3>
          <h6>
            A collection of training and knowledge driven videos to help you
            succeed.
          </h6>
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} className="messageModal">
        <Modal.Header className="modal-title" closeButton closeLabel="">
          <Modal.Title>{`${to} question`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <Form>
            <p>Please note all questions will be answered within 72 hours.</p>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                onChange={handleOnChange}
                value={name}
              />
            </Form.Group>
            <Form.Group style={{ padding: '10px' }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                onChange={handleOnChange}
                value={email}
              />
            </Form.Group>
            <Form.Group
              style={{ padding: '10px' }}
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Marketing Question</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="messageBody"
                type="text"
                value={messageBody}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Button
              style={{ margin: '10px' }}
              variant="primary"
              type="submit"
              onClick={handleMarketingSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <GoToTop />
    </div>
  )
}
