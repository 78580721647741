import Dropdown from 'react-bootstrap/Dropdown';
import GoToTop from '../../Components/GoToTop';

export default function Guidelines() {
  return (
    <div className="guidelinesPage">
      <h2>
        Guidelines, Forms, & Cheat
        <span style={{ color: '#009ee5' }}> Sheets</span>
      </h2>
      <div className="guidelineDropdowns">
        <Dropdown style={{ padding: '0 10px 0 10px' }}>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Guidelines
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FGuideline-ConventionalLoans.pdf?alt=media&token=a5ba78db-da3e-4353-ac05-1edd61778da9"
              target="_blank"
            >
              Conventional Loans
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FVAEligibilityGuide.pdf?alt=media&token=9eb5d3b3-a454-445f-9317-7462cc45ff40"
              target="_blank"
            >
              VA Eligibility Guide
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FSourcingDeposits.pdf?alt=media&token=42ad3f70-36da-4d9b-9ffa-824dbb43f2c0"
              target="_blank"
            >
              Sourcing Deposits
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown style={{ padding: '0 10px 0 10' }}>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Sub Forms
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
          <Dropdown.Item
                          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/purchaseTools%2FPURCHASE%20CONTACT%20SHEET%20.pdf?alt=media&token=77cbac39-5f62-4a15-99e4-3958d91f0086"
              target="_blank"
            >
              Purchase Contact Sheet
            </Dropdown.Item>
          <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FNo%20Income%3ANo%20Employment%20Purchase%20Submission%20Form.pdf?alt=media&token=882cb70c-e620-44b6-8678-fbff0c9df906"
              target="_blank"
            >
              No Income/No Employment Purchase Submission Form
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FNo%20Income%3ANo%20Employment%20Refinance%20Submission%20Form.pdf?alt=media&token=92ce4a16-9e31-407a-bd37-cd5cc7dec50d"
              target="_blank"
            >
              No Income/No Employment Refinance Submission Form
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FBank%20Statement%20Purchase.pdf?alt=media&token=12648b0f-c454-4663-ad17-7e718f4e0e7c"
              target="_blank"
            >
              Bank Statement Purchase Loan
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FBank%20Statement%20Refinance.pdf?alt=media&token=90a10f5b-dcdb-4736-9154-4e15bd477fe5"
              target="_blank"
            >
              Bank Statement Refinance Loan
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFanniePurchase.pdf?alt=media&token=984828d0-3d02-4a72-93a9-d0b1d4e6deea"
              target="_blank"
            >
              Fannie Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFannieRefinance.pdf?alt=media&token=228ce5a9-8e4a-436e-af6c-90297fac564f"
              target="_blank"
            >
              Fannie Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFHAPurchase.pdf?alt=media&token=3055da7a-bec2-47ff-bfa8-484c8d81ba08"
              target="_blank"
            >
              FHA Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFHA%20Refinance.pdf?alt=media&token=04aa8e57-417b-4eb8-b3f8-92719df5bf7d"
              target="_blank"
            >
              FHA Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFHA%20Streamline%20Refinance.pdf?alt=media&token=ec2be90b-9aa9-41a7-a74e-ca24078d13a4"
              target="_blank"
            >
              FHA Streamline Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFreddiePurchase.pdf?alt=media&token=954e8db9-02de-4119-af13-a4aed29bb0c7"
              target="_blank"
            >
              Freddie Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FFreddieRefinance.pdf?alt=media&token=01a6f7ab-c2dc-4d40-94fb-c9edd12276f5"
              target="_blank"
            >
              Freddie Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FJumboPurchase.pdf?alt=media&token=6d0f720d-866a-40e7-b5b3-7a18ac5147c3"
              target="_blank"
            >
              Jumbo Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FJumboRefinance.pdf?alt=media&token=9caad2d2-6217-4074-be62-29a2a23c154a"
              target="_blank"
            >
              Jumbo Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FVA%20IRRRL.pdf?alt=media&token=4b68f3ea-947d-4412-aa94-397a719374b4"
              target="_blank"
            >
              VA IRRRL
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FVA%20Purchase.pdf?alt=media&token=5488a355-7de4-455b-bdcf-6d4e2e4c53a8"
              target="_blank"
            >
              VA Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FVARefinance.pdf?alt=media&token=c7601a2c-767b-4c41-a82e-b09896f31267"
              target="_blank"
            >
              VA Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FForeign%20National%20Purchase.pdf?alt=media&token=b7a2ad3a-9136-43b6-b7e8-d998f451374a"
              target="_blank"
            >
              Foreign National Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FForeign%20National%20Refinance.pdf?alt=media&token=e5fa052d-f785-44f7-9d3d-3f8729a9c864"
              target="_blank"
            >
              Foreign National Refinance
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FDebt%20Service%20Coverage%20Ratio%20(DSCR)%20Purchase%20Submission%20Form.pdf?alt=media&token=578df08e-ce69-4aaa-9da8-a4f392529a34"
              target="_blank"
            >
              DSCR Purchase
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FDebt%20Service%20Coverage%20Ratio%20(DSCR)%20Refinance%20Submission%20Form.pdf?alt=media&token=dee7f384-44fd-4ed9-93c0-62b401bf0928"
              target="_blank"
            >
              DSCR Refinance
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown style={{ padding: '0 10px 0 10' }}>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            General Forms
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
          <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FForeignNationalPortugese.pdf?alt=media&token=cc6d0671-c2bc-4e14-820f-f4c09422bfa8"
              target="_blank"
            >
              Portuguese Documentation
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FForeignNationalDocs.pdf?alt=media&token=712cf794-a171-43fb-b9d5-4f94c2a44f7b"
              target="_blank"
            >
              Spanish Documentation
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FGift%20Letter%20for%20Conventional%20and%20VA%20Loans.pdf?alt=media&token=c07ba760-47e9-4920-a127-1da1bcc94131"
              target="_blank"
            >
              Gift Letter for Conventional and VA Loans
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FForm-GiftLetterforFHALoans.pdf?alt=media&token=e85a791c-8f0c-4a14-8367-4a7fa3a0b7c4"
              target="_blank"
            >
              Gift Letter for FHA Loans
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="light" className="dropdown" size="lg">
            Cheat Sheets
          </Dropdown.Toggle>
          <Dropdown.Menu variant="light">
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FCheatSheet-BKandForclosure.pdf?alt=media&token=e2dabaa1-d4d4-4d23-a0bc-1ea41fd5ef14"
              target="_blank"
            >
              BK and Foreclosure
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FCheatSheet-CollectionsandChargeOffs.pdf?alt=media&token=d4fb5a00-4e77-40b0-9aa0-4d7b62accfab"
              target="_blank"
            >
              Collections and Charge-Offs
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FPROPERTY%20TAXES%20PDF%5B93%5D.pdf?alt=media&token=1838afda-e83d-4dbd-bf08-31f700731e78"
              target="_blank"
            >
              Property Tax Due Dates
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FCheatSheet-StudentLoans.pdf?alt=media&token=7518270a-e0a8-488f-b70d-07853921de9e"
              target="_blank"
            >
              Student Loans
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FCheatSheet-VAIRRRLRecoup.pdf?alt=media&token=2731c98e-1d3d-4742-87a6-f1d762045280"
              target="_blank"
            >
              VA IRRRL Recoup
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FProgramGuidelineCheatSheet.pdf?alt=media&token=ee9bb3d6-6cf0-4463-bd8e-1bb532511b85"
              target="_blank"
            >
              Program Guideline Summary
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FStateSpousalCheatSheet.xlsx?alt=media&token=edbe20e1-234b-4ecf-85a0-98970ae600c4"
              target="_blank"
            >
              Spousal States
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FVASponsorIDs-VAIRRRLCase.pdf?alt=media&token=a545462d-9d4d-46f3-8fff-5cae46faec32"
              target="_blank"
            >
              VA Sponsor ID's VA IRRRL Case #
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FRed%20Flags%20Guidelines.pdf?alt=media&token=94bb0d03-c66c-41b2-ab0a-c74ebf64c1e0"
              target="_blank"
            >
              Red Flags Guidelines
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FManufactured%20Homes%20Guidelines.pdf?alt=media&token=742ba7eb-97bc-4212-8ca8-b2a014580c43"
              target="_blank"
            >
              Manufactured Homes Guidelines
            </Dropdown.Item>
            <Dropdown.Item
              href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FRed%20Flags.pdf?alt=media&token=e197e679-b12a-4d84-83ba-ee8e5533c587"
              target="_blank"
            >
              Income Red Flags
            </Dropdown.Item>
            <Dropdown.Item
                href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/guidelinesForms%26CheatSheets%2FPROFIT%20LOSS%20%2B%20BALANCE%20SHEET.xlsx?alt=media&token=f377b2a1-0e79-4edf-bf99-19c76c8532ba"
                target="_blank"
            >
               Profit Loss + Balance Sheet Template
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <GoToTop />
    </div>
  );
}
