import GoToTop from '../../Components/GoToTop'

export default function HRVideos() {
  return (
    <div className="hrVideosPage">
      <h2>
        Human Resource <span style={{ color: '#009ee5' }}>Videos</span>
      </h2>
      <div className="videoContainer">
        <div className='videos'>
          <video
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FScreen%20Shot%202021-10-31%20at%202.07.45%20PM.png?alt=media&token=1943c216-8201-4b7c-871f-2b0817065fca"
            width="600"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FNewHireOrientation.mp4?alt=media&token=3d7d8e18-89d4-4a58-b738-8299af13b55f"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
      <GoToTop />
    </div>
  )
}
