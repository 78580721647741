import Button from 'react-bootstrap/Button'
import GoToTop from '../../Components/GoToTop'

export default function OvercomingObjections() {
  return (
    <div className="overcomingObjectionsPage">
      <div>
        <h2>
          Scripts & Objection and{' '}
          <span style={{ color: '#009ee5' }}>Rebuttals</span>
        </h2>
        <h5>The key to overcoming objections is to: </h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>{' '}
          Acknowledge
        </h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>{' '}
          Respond
        </h5>
        <h5>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
            />
          </svg>
          <span style={{ color: '#009ee5', fontWeight: 'bold' }}> Pivot</span>
        </h5>
      </div>
      <div className="overcomingObjectionsBtns">
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/overcomingObjections%2FObjections%20%26%20Rebuttals.pdf?alt=media&token=064fa90e-101b-462c-8297-55966b945aff"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/objections&Rebuttals.png"
            alt="screenshot of objection and rebuttals pdf"
            style={{ width: '60%' }}
          />
          <br />
          Objections & Rebuttals
        </Button>
        &nbsp;
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/overcomingObjections%2FPURCHASE%20OBJECTIONS%20AND%20REBUTTALS.pdf?alt=media&token=b144ed3f-adf9-4db4-a3e7-b4a93a111bdf"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/purchaseO&R.png"
            alt="screenshot of purchase obections and rebuttals pdf"
            style={{ width: '60%' }}
          />
          <br />
          Purchase Objections and Rebuttals
        </Button>
        &nbsp;
        <Button
          className="btn"
          variant="link"
          href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/overcomingObjections%2FRealtor%20Pitch.docx?alt=media&token=83cac01d-4397-42c1-983b-164ae22663fd"
          target="_blank"
          style={{ width: '45%' }}
        >
          <img
            src="/assets/Screen Shot 2022-02-15 at 7.40.58 AM.png"
            alt=""
            style={{ width: '60%' }}
          />
          <br />
          Realtor Pitch
        </Button>
      </div>
      <GoToTop />
    </div>
  )
}
