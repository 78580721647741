import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import GoToTop from '../../Components/GoToTop'

export default function ClientGifts() {
  return (
    <div className="clientGiftsPage">
      <h2>
        Client <span style={{ color: '#009ee5' }}>Gifts</span>
      </h2>
      <Card style={{ padding: '10px' }}>
        <h5>Personalized Wine Glasses</h5>
        <Card.Body>
          <Button
            className="Btn"
            href="https://www.personalizationmall.com/Luigi-Bormioli-Lavish-Last-Name-Engraved-21oz-Stemless-Wine-Glass-i85522.item?productid=30544&sdest=Search&sdestid=117475339"
            target="_blank"
          >
            <img
              src="/assets/Wine Glass.png"
              alt="Client gift of a wine glass"
              style={{ margin: '10px', width: '200px' }}
            />
            <br />
            Purchase Now
          </Button>
        </Card.Body>
      </Card>
      <Card style={{ padding: '10px' }}>
        <h5>Personalized Cutting Board</h5>
        <Card.Body>
          <Button
            className="Btn"
            href="https://www.personalizationmall.com/Decorative-Name-Initial-Personalized-Butcher-Block-Cutting-Board-i55556.item?productid=20561&sdest=cart"
            target="_blank"
          >
            <img
              src="/assets/Cutting Board.png"
              alt="Client gift of a cutting board"
              style={{ margin: '10px', width: '200px' }}
            />
            <br />
            Purchase Now
          </Button>
        </Card.Body>
      </Card>
      <Card style={{ padding: '10px' }}>
        <h5>Personalized Candles</h5>
        <Card.Body>
          <Button
            className="Btn"
            href="https://www.personalizationmall.com/Farmhouse-Initial-Personalized-Triple-Pour-Candle-Jar-p24406.prod?sdest=search-op&sdestid=62883522"
            target="_blank"
          >
            <img
              src="/assets/Candle.png"
              alt="Client gift of a candle"
              style={{ margin: '10px', width: '200px' }}
            />
            <br />
            Purchase Now
          </Button>
        </Card.Body>
      </Card>
      <GoToTop />
    </div>
  )
}
