import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useState, createContext } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import firebase from 'firebase'

import GoToTop from './Components/GoToTop'
import Footer from './Components/Footer'
import Header from './Components/Header'
import Token from './Scenes/Welcome/Token'
import SignUp from './Scenes/Welcome/SignUp'
import Employee from './Scenes/Welcome/Employee'
import Home from './Scenes/Welcome/Home'
import Resource from './Scenes/Welcome/Resource'
import FoundersMessage from './Scenes/Welcome/FoundersMessage'
import LoanOfficerBenefits from './Scenes/Welcome/LoanOfficerBenefits'
import Licensing from './Scenes/Welcome/Licensing'
import HumanResources from './Scenes/HumanResources/HumanResources'
import FAQ from './Scenes/HumanResources/FAQ'
import PayrollSubmit from './Scenes/HumanResources/PayrollSubmit'
import HRVideos from './Scenes/HumanResources/HRVideos'
import LOTools from './Scenes/LoanOfficerTools/LOTools'
import CreditBoost from './Scenes/LoanOfficerTools/CreditBoost'
import Guidelines from './Scenes/LoanOfficerTools/Guidelines'
import Worksheets from './Scenes/LoanOfficerTools/WorkSheets'
import PurchaseTools from './Scenes/LoanOfficerTools/PurchaseTools'
import ToolsVideos from './Scenes/LoanOfficerTools/ToolsVideos'
import Technology from './Scenes/Technology/Technology'
import PricingEngine from './Scenes/Technology/PricingEngine'
import CRM from './Scenes/Technology/CRM'
import LoanOriginator from './Scenes/Technology/LoanOriginator'
import MortgageProducts from './Scenes/MortgageProducts/MortgageProducts'
import InvestorsContact from './Scenes/MortgageProducts/InvestorsContact'
import Traditional from './Scenes/MortgageProducts/Traditional'
import NonTraditional from './Scenes/MortgageProducts/NonTraditional'
import MortgageVideos from './Scenes/MortgageProducts/MortgageVideos'
import Sales from './Scenes/Sales/Sales'
import BuildingBusiness from './Scenes/Sales/BuildingBusiness'
import SalesVideo from './Scenes/Sales/SalesVideo'
import OvercomingObjections from './Scenes/Sales/OvercomingObjections'
import Marketing from './Scenes/Marketing/Marketing'
import Articles from './Scenes/Marketing/Articles'
import MarketingVideos from './Scenes/Marketing/MarketingVideos'
/*import DigitalDesignTemps from './Scenes/Marketing/DigitalDesignTemps'*/
import PrintDesignTemps from './Scenes/Marketing/PrintDesignTemps'
import FacebookGallery from './Scenes/Marketing/FacebookGallery'
import InstagramGallery from './Scenes/Marketing/InstagramGallery'
import LinkedInGallery from './Scenes/Marketing/LinkedInGallery'
import LinkedinBanner from './Scenes/Marketing/LinkedinBanners'
import LinkedinPosts from './Scenes/Marketing/LinkedinPosts'
import MarketingTemplates from './Scenes/Marketing/MarketingTemplates'
import TopInvestors from './Scenes/MortgageProducts/TopInvestors'
import CommonQualifying from './Scenes/MortgageProducts/CommonQualifying'
import Conventional from './Scenes/MortgageProducts/Conventional'
import GovProducts from './Scenes/MortgageProducts/GovProducts'
import Jumbo from './Scenes/MortgageProducts/Jumbo'
import CommercialLoans from './Scenes/MortgageProducts/CommercialLoans'
import BridgeLoans from './Scenes/MortgageProducts/BridgeLoans'
import ForeignNationals from './Scenes/MortgageProducts/ForeignNationals'
import DSCRLoan from './Scenes/MortgageProducts/DSCRLoan'
import BankStatement from './Scenes/MortgageProducts/BankStatement'
import YR40 from './Scenes/MortgageProducts/YR40'
import FacebookPosts from './Scenes/Marketing/FacebookPosts'
import CoverPhotos from './Scenes/Marketing/CoverPhotos'
import HighlightCovers from './Scenes/Marketing/HighlightCover'
import InstagramPosts from './Scenes/Marketing/InstagramPosts'
import HypotecStore from './Scenes/Marketing/HypotecStore'
import ClientGifts from './Scenes/Marketing/ClientGifts'
import Benefits from './Scenes/HumanResources/Benefits'
import Compliance from './Scenes/Welcome/Compliance'
import NoIncomeLoan from './Scenes/MortgageProducts/NoIncomeLoan'
import Required from './Scenes/Welcome/Required'
import MarketingCompliance from './Scenes/Marketing/MarketingCompliance'
import StateLicensing from './Scenes/Footer/StateLicensing'
import PrivacyPolicy from './Scenes/Footer/PrivacyPolicy'
import ContactUs from './Scenes/Footer/ContactUs'
import Vendors from './Scenes/Sales/Vendors'
import ProtectedRoute from './Components/ProtectedRoute'

const firebaseAuth = firebase.auth()
export const UserAuthContext = createContext(null)

function App() {
  const [user, setUser] = useState(null)
  return (
    <>
      <Router>
        <UserAuthContext.Provider
          value={{ user, setUser, firebaseAuth, firebase }}
          >
          <GoToTop />
          <Header />
          <Switch>
            <Route exact path="/token" component={Token} />
            <Route exact path="/licensing" component={Licensing} />
            <Route exact path="/LObenefits" component={LoanOfficerBenefits} />
            <Route exact path="/founderMessage" component={FoundersMessage} />
            <ProtectedRoute exact path="/required" component={Required} />
            <ProtectedRoute exact path="/employee" component={Employee} />
            <ProtectedRoute exact path="/resource" component={Resource} />
            <ProtectedRoute exact path="/compliance" component={Compliance} />

            <ProtectedRoute exact path="/sales" component={Sales} />
            <ProtectedRoute
              exact
              path="/buildingBusiness"
              component={BuildingBusiness}
            />
            <ProtectedRoute
              exact
              path="/overcomingObjections"
              component={OvercomingObjections}
            />
            <Route exact path="/vendors" component={Vendors} />
            <ProtectedRoute exact path="/salesVideo" component={SalesVideo} />

            <ProtectedRoute exact path="/marketing" component={Marketing} />
            <ProtectedRoute exact path="/articles" component={Articles} />
            <ProtectedRoute
              exact
              path="/marketingVideos"
              component={MarketingVideos}
            />
                      {/* <ProtectedRoute exact path="/digital" component={DigitalDesignTemps} /> */}
            <ProtectedRoute exact path="/marketingTemplates" component={MarketingTemplates} />
            <ProtectedRoute
              exact
              path="/linkedinPosts"
              component={LinkedinPosts}
            />
            <ProtectedRoute
              exact
              path="/linkedinBanners"
              component={LinkedinBanner}
            />
            <ProtectedRoute exact path="/print" component={PrintDesignTemps} />
            <ProtectedRoute
              exact
              path="/linkedInImgs"
              component={LinkedInGallery}
            />
            <ProtectedRoute
              exact
              path="/facebookImgs"
              component={FacebookGallery}
            />
            <ProtectedRoute
              exact
              path="/instagramImgs"
              component={InstagramGallery}
            />
            <ProtectedRoute exact path="/coverPhotos" component={CoverPhotos} />
            <ProtectedRoute
              exact
              path="/highlightCover"
              component={HighlightCovers}
            />
            <ProtectedRoute exact path="/igPosts" component={InstagramPosts} />
            <ProtectedRoute exact path="/fbPosts" component={FacebookPosts} />
            <ProtectedRoute exact path="/store" component={HypotecStore} />
            <ProtectedRoute exact path="/gifts" component={ClientGifts} />
            <ProtectedRoute
              exact
              path="/marketingCompliance"
              component={MarketingCompliance}
            />

            <ProtectedRoute exact path="/LOTools" component={LOTools} />
            <ProtectedRoute
              exact
              path="/worksheetTools"
              component={Worksheets}
            />
            <ProtectedRoute exact path="/guidelines" component={Guidelines} />
            <ProtectedRoute
              exact
              path="/purchaseTools"
              component={PurchaseTools}
            />
            <ProtectedRoute exact path="/creditBoost" component={CreditBoost} />
            <ProtectedRoute
              exact
              path="/LOToolsVideos"
              component={ToolsVideos}
            />

            <ProtectedRoute
              exact
              path="/products"
              component={MortgageProducts}
            />
            <ProtectedRoute
              exact
              path="/investorsContact"
              component={InvestorsContact}
            />
            <ProtectedRoute exact path="/traditional" component={Traditional} />
            <ProtectedRoute
              exact
              path="/nonTraditional"
              component={NonTraditional}
            />
            <ProtectedRoute
              exact
              path="/mortgageVideos"
              component={MortgageVideos}
            />
            <ProtectedRoute
              exact
              path="/topInvestors"
              component={TopInvestors}
            />
            <ProtectedRoute exact path="/common" component={CommonQualifying} />
            <ProtectedRoute
              exact
              path="/conventional"
              component={Conventional}
            />
            <ProtectedRoute exact path="/govProducts" component={GovProducts} />
            <ProtectedRoute exact path="/jumbo" component={Jumbo} />
            <ProtectedRoute
              exact
              path="/commercialLoans"
              component={CommercialLoans}
            />
            <ProtectedRoute exact path="/bridgeLoans" component={BridgeLoans} />
            <ProtectedRoute
              exact
              path="/foreignNationals"
              component={ForeignNationals}
            />
            <ProtectedRoute exact path="/dscrLoan" component={DSCRLoan} />
            <ProtectedRoute
              exact
              path="/bankStatement"
              component={BankStatement}
            />
            <ProtectedRoute exact path="/yr40" component={YR40} />
            <ProtectedRoute exact path="/noIncome" component={NoIncomeLoan} />

            <ProtectedRoute exact path="/technology" component={Technology} />
            <ProtectedRoute
              exact
              path="/loanOriginator"
              component={LoanOriginator}
            />
            <ProtectedRoute
              exact
              path="/pricingEngine"
              component={PricingEngine}
            />
            <ProtectedRoute exact path="/crm" component={CRM} />

            <ProtectedRoute exact path="/hr" component={HumanResources} />
            <ProtectedRoute exact path="/faq" component={FAQ} />
            <ProtectedRoute
              exact
              path="/payrollSubmit"
              component={PayrollSubmit}
            />
            <ProtectedRoute exact path="/hrVideos" component={HRVideos} />
            <ProtectedRoute exact path="/benefits" component={Benefits} />

            <Route exact path="/stateLicensing" component={StateLicensing} />
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
            <Route exact path="/contactUs" component={ContactUs} />

            <Route exact path="/signup">
              <SignUp setUser={setUser} />
            </Route>
            <Route exact path="/">
              <Home user={user} setUser={setUser} />
            </Route>
          </Switch>
          <Footer />
        </UserAuthContext.Provider>
      </Router>
    </>
  )
}

export default App
